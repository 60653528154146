<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="mb-32 flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          MARI BERKENALAN
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Tim Luar Biasa Kami
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Berkenalan dengan orang-orang yang membantu menciptakan Kiraket sebagai sebuah perusahaan
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-40"></div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in team"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2"
        >
          <CardFour
            :title="item.name"
            :subtitle="item.job"
            :description="item.description"
          >
            <img
              v-if="item.avatar === 'rinaldi'"
              src="@/assets/image/team/rinaldi.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'idin'"
              src="@/assets/image/team/idin.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'vebby'"
              src="@/assets/image/team/vebby.png"
              class="w-24 rounded-lg"
            />
            <img
              v-if="item.avatar === 'avatar-blank'"
              src="@/assets/image/team/avatar-blank.png"
              class="w-24 rounded-lg"
            />
          </CardFour>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardFour from '@/components/card/CardFour.vue'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: {
    CardFour,
  },
  mixins: [globalMixin],
  data: () => ({
    team: [
      // {
      //   id: 1,
      //   name: 'Rinaldi Saputra',
      //   job: 'Founder',
      //   avatar: 'rinaldi',
      //   description:
      //     'Seorang pemimpin yang terbukti dan inovatif dengan banyak pengalaman internasional dalam membangun dan menumbuhkan tim berkinerja tinggi secara menguntungkan. Usaha terbarunya adalah dengan Kiraket - sebuah ide dan konsep yang telah digodok selama lebih dari 10 tahun. Rinaldi membawa semangat dan pengetahuan dan sangat dihormati sebagai profesional Transformasi Bisnis dan pakar teknologi besar dengan keahlian di Produk Konsumen dan Ritel.',
      // },
      // {
      //   id: 2,
      //   name: 'Ahmad Muhyidin',
      //   job: 'Chief Technology Officer',
      //   avatar: 'idin',
      //   description:
      //     '5+ tahun dalam siklus Ideation to Market: - Manajemen bisnis, pengembangan produk, pengembangan pasar; dalam lingkungan global, korporat, dan kewirausahaan yang sangat beragam. Telah bekerja dengan perusahaan besar dan kecil.',
      // },
      {
        id: 1,
        name: 'Vebbyana Agusti',
        job: 'Head Bussines & Sales',
        avatar: 'vebby',
        description:
          'Seorang pemimpin yang terbukti dan inovatif dengan banyak pengalaman internasional dalam membangun dan menumbuhkan tim berkinerja tinggi secara menguntungkan. Usaha terbarunya adalah dengan Kiraket - sebuah ide dan konsep yang telah digodok selama lebih dari 10 tahun. Rinaldi membawa semangat dan pengetahuan dan sangat dihormati sebagai profesional Transformasi Bisnis dan pakar teknologi besar dengan keahlian di Produk Konsumen dan Ritel.',
      },
      {
        id: 2,
        name: 'Ahmad Muhyidin',
        job: 'Head Technology',
        avatar: 'idin',
        description:
          '5+ tahun dalam siklus Ideation to Market: - Manajemen bisnis, pengembangan produk, pengembangan pasar; dalam lingkungan global, korporat, dan kewirausahaan yang sangat beragam. Telah bekerja dengan perusahaan besar dan kecil.',
      },
      // {
      //   id: 3,
      //   name: 'Guntur',
      //   job: 'General Manager',
      //   avatar: 'avatar-blank',
      //   description: '',
      // },
    ],
  }),
  methods: {},
}
</script>
