<template>
  <div class="align-center fixed z-50 flex h-full w-full justify-center">
    <div class="flex justify-center" :class="deviceWidth">
      <div
        wire:loading
        class="z-50 flex h-screen flex-col items-center justify-center overflow-hidden bg-gray-100 opacity-75"
        :class="deviceWidth"
      >
        <div
          wire:loading
          class="z-50 flex h-screen flex-col items-center justify-center overflow-hidden"
          :class="deviceWidth"
        >
          <div
            class="w-90 h-90 bg-kira-light flex flex-col items-center justify-center rounded-md p-4 opacity-100 shadow"
          >
            <div
              class="loader-animation mb-4 h-12 w-12 rounded-full border-4 border-t-4 border-gray-200 ease-linear"
            ></div>
            <h2 class="text-center text-sm font-semibold text-gray-400">
              Loading...
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingIndicator',
  inject: ['deviceWidth'],
}
</script>
