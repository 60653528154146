<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          KEMITRAAN AKSES AWAL
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Dapatkan akses awal ke platform kami
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          untuk mendapatkan penawaran awal terbaik
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          yang dapat kami tawarkan
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>

      <!-- <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img
            src="@/assets/image/home-transparent.png"
            class="w-full rounded-xl"
          />
        </div>
      </div> -->
    </div>

    <div class="mb-32 flex w-full flex-col bg-gray-100">
      <div class="relative flex bg-gray-100 py-20">
        <LinesDotted class="absolute w-full" />
        <div class="z-20 flex w-full flex-col px-6 lg:px-32">
          <div class="mb-10 flex w-full flex-col items-center text-center">
            <p class="text-sm tracking-widest text-gray-400 md:text-base">
              RENCANA KEANGGOTAAN
            </p>
          </div>
          <div class="flex w-full">
            <div class="w-1/3">
              <div class="flex flex-col">
                <div class="mt-14 flex py-4">
                  <p class="text-xs md:text-sm">
                    Akses Awal ke Data Platform
                  </p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Gratis di Platform Kiraket</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Sesuaikan Desain & Wawasan</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Diskon Eksklusif</p>
                </div>
              </div>
            </div>
            <div class="w-2/3">
              <div class="flex flex-col rounded-lg bg-white">
                <div class="flex border-b-2 py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <div class="flex items-center">
                      <MemberShipBronze class="h-4" />
                    </div>
                    <div class="flex items-center pl-2">
                      <p class="text-md font-black">Bronze</p>
                    </div>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <div class="flex items-center">
                      <MemberShipSilver class="h-4" />
                    </div>
                    <div class="flex items-center pl-2">
                      <p class="text-md font-black">Silver</p>
                    </div>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <div class="flex items-center">
                      <MemberShipGold class="h-4" />
                    </div>
                    <div class="flex items-center pl-2">
                      <p class="text-md font-black">Gold</p>
                    </div>
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <CircleCheck class="text-kira-success h-4" />
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <CircleCheck class="text-kira-success h-4" />
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <CircleCheck class="text-kira-success h-4" />
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">5</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">10</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">15</p>
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">-</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <CircleCheck class="text-kira-success h-4" />
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <CircleCheck class="text-kira-success h-4" />
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">5%</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">10%</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-sm text-gray-400">15%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-10 flex w-full flex-col">
            <p class="text-md tracking-widest">Komitmen dari Mitra Kami</p>
          </div>
          <div class="flex w-full">
            <div class="w-1/3">
              <div class="flex flex-col">
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Ekspresi Minat</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Distribusi Kiraket</p>
                </div>
                <div class="flex py-4">
                  <p class="text-xs md:text-sm">Bergabung dengan Pemasaran</p>
                </div>
              </div>
            </div>
            <div class="w-2/3">
              <div class="flex flex-col rounded-lg bg-white">
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">-</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                </div>
                <div class="flex py-4">
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">-</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">-</p>
                  </div>
                  <div class="flex w-1/3 justify-center px-4">
                    <p class="text-xs text-gray-400 md:text-sm">Komitmen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'
import CircleCheck from '@/assets/svg/circle-check.svg'
import LinesDotted from '@/assets/svg/lines-dotted.svg'
import MemberShipBronze from '@/assets/svg/membership/bronze.svg'
import MemberShipSilver from '@/assets/svg/membership/silver.svg'
import MemberShipGold from '@/assets/svg/membership/gold.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
    CircleCheck,
    LinesDotted,
    MemberShipBronze,
    MemberShipSilver,
    MemberShipGold,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Actionable Real Time Information',
        description:
          'The Traditional channel consisting of hundreds of thousands of small stores is by far your single largest channel yet its extremely difficult to obtain actionable and timely supply chain information from it. Kiraket’s solutions for Supply Chain gives you real-time sales and inventory data figures to track daily sales, in-store availability and overall channel effectiveness by distribution channel whether direct or indirect. Make your demand planning, distribution planning and in-store availability far more effective by using our unique historical and predictive analytical data and toolset.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'The Kiraket Advantage',
        description:
          'We collect and track real-time sales data from tens of thousands of traditional retailers and make this information available to you to consume in real-time either through links to your existing analytics solutions or using our insight and analytics workbench. Our unique platform allows you not only to analyze past data but connect in real-time with small retailers to conduct real-time experiments, surveys, and sentiment analysis. Obtain the feedback and information you need in real-time to make your marketing much more effective and generate a much higher return on marketing spend.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Supply Chain Leader',
        description:
          'Monitor the effectiveness of your supply chain down to the crucial ‘last mile’ of the traditional channel.  use the data from Kiraket to more effectively plan and manage your Supply Chain activities to improve service levels at the store level and to lower costs.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'S&OP Leader',
        description:
          'Use the data from the Kiraket platform to make your S&OP planning much more effective by utilizing the trend and actual data in the Kiraket platform to more effectively plan supply/demand decisions, promotional campaigns, and new product launches.',
        icon: 'USER',
      },
      {
        id: 3,
        title: 'Distribution Manager',
        description:
          'Kiraket’s inventory and sales velocity data can be used to more effectively plan and manage your distribution channel and assets to ensure the highest service levels at the lowest cost.  Effectively manage your channel partners or view new direct delivery options by using our real time data to plan, simulate and act effectively.',
        icon: 'USER',
      },
      {
        id: 4,
        title: 'Transportation Manager',
        description:
          'Incorporate our data into your transportation planning to better prioritize and schedule routes, take advantage of reverse logistics opportunities and better manage returnable packaging transportation.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
