export const colors = {
  primary: '#47D9BF',
  // primary: '#1ABC9C',
  secondary: '#FFAD16',
  // secondary: '#F39C12',
  success: '#47D9D8',
  danger: '#F46D6D',

  warning: '#D9D047',
  dark: '#0f172A',
  light: '#ffffff',
}
