<template>
  <div
    class="flex-center flex w-full justify-start rounded-xl border-2 border-gray-100 p-4 transition duration-500 hover:scale-105"
  >
    <div class="flex items-start">
      <slot></slot>
    </div>
    <div class="flex w-full flex-col pl-4">
      <p class="mt-1 text-sm text-black">{{ title }}</p>
      <p class="text-kira-primary mt-1 text-sm">{{ subtitle }}</p>
      <!-- <p class="mt-4 text-xs leading-6 text-gray-400">
        {{ description }}
      </p> -->
    </div>
  </div>
</template>

<script>
export default {
  title: 'CardFour',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  methods: {
    click() {
      this.$emit('onClick')
    },
  },
}
</script>
