export default {
  language: 'Languages',
  appName: 'Kiraket',
  greeting: 'Hello world!',
  hello: 'Hello',
  location: 'Jakarta',
  home: 'Home',
  category: 'Category',
  tenants: 'Tenants',
  menus: 'Menu',
  tenantNear: 'Tenant Nearby',
  order: 'Order',
  lastOrdered: 'Last Ordered',
  orderHistory: 'Order History',
  recommendationForYou: 'Recommendation For You',
  fromTenant: 'From this Tenant',
  seeAll: 'See All',
  seeHistory: 'See History',
  seeTenant: 'See Tenant',
  detail: 'Detail',
  seeDetail: 'See Details',
  payNow: 'Pay Now',
  changeMethod: 'Change Method',
  payOrder: 'Pay Order',
  seeHowToPay: 'See How To Pay',
  sale: 'Sale',
  cart: 'Cart',
  wallet: 'Wallet',
  points: 'Points',
  filter: 'Filter',
  sort: 'Sort',
  price: 'Price',
  minimal: 'Minimal',
  maximal: 'Maximal',
  account: 'Account',
  scanQR: 'Scan QR',
  scanAQRCode: 'Scan sebuah QR Code',
  product: 'Product',
  productCategory: 'Product Category',
  productRecommendation: 'Product Recommendation',
  notification: 'Notification',
  products: 'products',
  tenants: 'tenants',
  changeLanguage: 'Change Language',
  balance: 'Balance',
  balanceAfter: 'Balance after transaction',
  topup: 'Top up',
  pendingTopup: 'Pending Top up',
  myPoints: 'My Points',
  inviteFriends: 'Invite Friends',
  termsAndConditions: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
  logout: 'Log out',
  email: 'E-mail',
  password: 'Password',
  passwordConfirmation: 'Password Confirmation',
  forgotPassword: 'Forgot password?',
  forgotPIN: 'Forgot PIN?',
  resetPIN: 'Reset PIN',
  forgotPasswordDesc:
    'Please fill in the WhatsApp number that you used to register. You will be sent an WhatsApp number with instructions on how to reset your PIN.',
  submit: 'Submit',
  sendEmail: 'Send Email',
  sendOTP: 'Send OTP',
  resetPassword: 'Reset Password',
  resetPasswordDesc:
    'Your request password reset has been successfuly, please create a new strong password for account ',
  newPassword: 'New Password',
  newPasswordConfirmation: 'New Password Confirmation',
  verificationCheckEmail: 'Please check your email for verification code',
  login: 'Login',
  save: 'Save',
  welcome: 'Welcome',
  welcomeTo: 'Welcome to',
  asCustomer: 'As Customer',
  asTenant: 'As Tenant',
  back: 'Back',
  wrong_email_or_password: 'Wrong email or password',
  wrong_phone_or_pin: 'Wrong WhatsApp number or password',
  phone_number_not_valid: 'WhatsApp number is not valid',
  phone_number_required: 'WhatsApp number is required',
  information: 'Information',
  you_are_not_logged_in: 'You are not logged in',
  you_have_successfully_registered: 'You have successfully registered',
  registration_failed: 'Registration failed',
  you_have_successfully_logged_in: 'You have successfully logged in',
  enter_review: 'Please enter your review',
  error_send_review: 'Review failed to send',
  enter_email: 'Please enter your email',
  enter_password: 'Please enter your password',
  enter_passwordConfirmation: 'Please enter your password confirmation',
  enter_phoneNumber: 'Please enter your whatsapp number',
  enter_phoneNumber: 'Please enter your ewallet number',
  enter_verificationCode: 'Please enter your verification code',
  enter_name: 'Please enter your Name',
  valid_email_address: 'This field must be valid email address',
  valid_phone_number: 'Please enter valid WhatsApp number',
  valid_ewallet_number: 'Please enter valid ewallet number',
  valid_qty: 'Please enter valid qty',
  email_sent: 'Email Sent',
  otp_sent: 'OTP sent',
  account_verified: 'Account Verified',
  verify_code_true: 'Verification success',
  verify_code_digit: 'Verification Code must be 4 digit',
  pin_code_digit: 'Verification Code must be 6 digit',
  field_must_be_alphabet: 'must only contain alphabetic characters',
  field_must_be_alphanumeric: 'must only contain alphanumeric characters',
  field_must_be_numeric: 'must only contain numeric characters',
  password_only_allowed_alphanumeric_and_special_characters:
    'Password only allowed alphanumeric and special characters',
  confirmation: 'Confirmation',
  are_you_sure_want_to_logout: 'Are you sure want to logout?',
  yes: 'Yes',
  no: 'No',
  find_food_or_drink: 'Find food or drink',
  data_not_found: 'Data not found',
  tab_pending: 'Pending',
  tab_on_process: 'On Process',
  tab_done: 'Done',
  tab_cancelled: 'Cancelled',
  loginDesc: 'Enter your e-mail and password',
  noAccount: "Doesn't have account?",
  joinUs: 'Join us',
  register: 'Register',
  name: 'Name',
  phoneNumber: 'WhatsApp Number',
  insertPhoneNumberEwallet: 'Insert whatsapp number ewallet',
  verificationCode: 'Verification Code',
  registerAggreement: 'By clicking Next, you have read and agree to our',
  termsOfUse: 'Terms of Use',
  and: 'And',
  next: 'Next',
  haveAccount: 'Does have an account?',
  accountVerification: 'Account Verification',
  enterNewPin: 'Enter your new PIN',
  confirmNewPin: 'Confirm your new PIN',
  enterPin: 'Enter you PIN',
  resendCode: 'Resend code',
  institution: 'Institution',
  yourInstitution: 'Your Institution',
  idNumber: 'ID Number',
  enter_idNumber: 'This field must be valid ID Number',
  finish: 'Finish',
  continueShopping: 'Continue Shopping',
  cartEmptyNotice: 'Your cart is empty',
  cartEmptyNoticeDetail:
    'Come on, fill your basket by buying your favorite snacks',
  addToCart: 'Add to cart',
  add: 'Add',
  showResult: 'Show Result',
  reset: 'Reset',
  total: 'Total',
  with_fee: '(+fee)',
  point_gained: 'Point gained',
  subTotal: 'Subtotal',
  discount: 'Discount',
  unique_code: 'Unique code',
  point_reward: 'Point reward',
  promo: 'Promo',
  usePromo: 'Use promo',
  detailPromo: 'Promo detail',
  usingPromo: 'Use promo',
  youCanSave: 'You can save',
  enterPromoCode: 'Enter the promo code',
  promoEnd: 'Promo ends on ',
  promoValidUntil: 'Valid until ',
  promoDay: 'days again',
  showDetail: 'Show detail',
  applicableArea: 'Applicable Area',
  promoUsed: '1 promo have been used',
  use: 'Use',
  checkout: 'Checkout',
  delete: 'Delete',
  chooseAll: 'Choose All',
  addNote: 'Add Note',
  note: 'Notes',
  edit: 'Edit',
  foods: 'Foods',
  drinks: 'Drinks',
  search_tenant: 'Search Tenant',
  search_menu: 'Search Menu',
  success: 'Success',
  successfully_added_to_cart: 'Successfully added to cart',
  failed_add_to_cart: 'Failed add to cart!',
  successfully_add_qty: 'Successfully add qty',
  successfully_sub_qty: 'Successfully sub qty',
  success_change_note: 'Successfully change notes',
  successfully_delete_from_cart: 'Successfully delete from cart',
  success_get_data: 'Successfully get the data',
  failed: 'Failed',
  failed_get_data: 'Failed to get data',
  description: 'Description',
  detail_product: 'Product Detail',
  process_time: 'Process Time',
  availability: 'Availability',
  in_stock: 'In Stock',
  out_stock: 'Out of Stock',
  product_not_found: 'Product detail not found',
  promo_not_found: 'Promo detail not found',
  serving_for: 'Serving for',
  serving_for_later: 'Serving for later',
  oclock: 'Time',
  select_date: 'Select date',
  select_hour: 'Select hour',
  select_minute: 'Select minute',
  now: 'Now',
  process_payment: 'Process payment',
  process_payment_confirmation: 'Payment confirmation',
  pay: 'Pay now',
  with_cash: 'with cash',
  no_data: 'No data found',
  no_more_data: 'No more data showed',
  no_result: 'No results to display',
  no_photo_available: 'No photo available',
  order_success: 'Order success!',
  order_success_desc:
    'Thank you, your order is now on\nprocess by the merchant',
  order_failed: 'Order failed!',
  order_failed_desc:
    'Sorry, looks like you don’t have\nenough balance for this order',
  order_pending: 'Order Pending',
  status_pending: 'Pending',
  order_status: 'Your order status is ',
  order_pending_desc: 'Please make a purchase with this transaction',
  order_pending_cash_desc: 'Please go to the cashier to complete your payment',
  order_pending_subdesc: 'The payment amount must match ',
  order_pending_cash_subdesc: 'Please prepare money according to payment',
  last_3_digit: 'the last 3 digits',
  already_paid: 'I already paid',
  back_to_home: 'Back to Home',
  estimated_time: 'Estimated time:',
  minute: 'Minutes',
  openModal: 'Open Modal',
  track_your_order: 'Track your order',
  track_my_order: 'Track my order',
  have_you_order_yet: 'Have you order yet?',
  have_not_order_pending_description:
    'There is no pending order here. You can make new by click the button below.',
  order_no_item: 'Empty order',
  order_no_pending: "You don't have any\npending order",
  order_no_process: "You don't have any\nongoing order",
  order_no_done: "You don't have any\nfinished order",
  order_no_cancelled: "You don't have any\ncancelled order",
  your_order: 'Your order',
  id_order: 'Order ID',
  order_tab_pending: 'Pending',
  order_tab_onprocess: 'On process',
  order_tab_done: 'Done',
  order_tab_cancelled: 'Cancelled',
  tracking_cancelled: 'Cancelled',
  other_item: 'others',
  order_waiting_confirmation: 'Waiting for confirmation',
  order_ready: 'Order Ready',
  order_complete_payment: 'Complete Payment',
  order_reorder_btn: 'Re-order',
  order_ready_btn: 'Ready',
  order_onprocess_btn: 'On process',
  order_onprocess_desc: 'Estimated',
  time_hour: 'Jam',
  time_minute: 'Menit',
  order_cancel_btn: 'Cancel',
  order_cancel_title: 'Cancel order',
  order_cancel_all: 'Cancel all',
  order_cancel_no_item_selected:
    'Please select at least one item to cancel order',
  order_cancel_desc: 'Are you sure to cancel this order?',
  order_cancel_success: 'Order is canceled',
  order_cancel_failed: 'Order is failed to cancel',
  order_review_success: 'Order is successfully reviewed',
  order_review_failed: 'Order is failed to review',
  order_confirmed: 'Order confirmed',
  review_appbar: 'Leave review',
  review_only_shown_by: 'Your review will only be visible to tenants',
  give_rating: 'Give rating',
  et_rating: 'Please give rating first',
  write_review: 'Review',
  write_review_here: 'Write review here',
  add_photo: 'Add photo',
  gallery: 'Gallery',
  camera: 'Camera',
  track_appbar: 'Track your order',
  tracking_confirmed: 'Order confirmed',
  tracking_on_progress: 'On progress',
  tracking_almost_done: 'Almost done',
  tracking_ready: 'Order is ready',
  tracking_done: 'Done',
  tracking_completed: 'Completed',
  tracking_canceled: 'Order is canceled',
  tracking_status_confirmed: 'confirmed',
  tracking_status_on_progress: 'on progress',
  tracking_status_completed: 'completed',
  tracking_status_cancelled: 'cancelled',
  tracking_waiting_confirmation_title: 'Waiting for confirmation',
  tracking_waiting_confirmation_desc: 'Will be confirm at',
  tracking_complete_your_payment: 'Complete your payment',
  tracking_complete_your_payment_desc: 'Please complete your payment before',
  tracking_on_progress_title: 'Your order will be ready at',
  tracking_on_progress_desc: '@time1 - @time2',
  tracking_ready_title: 'Your order is ready!',
  tracking_ready_desc: 'You can pick up your order now',
  tracking_done_title: 'Order done',
  tracking_done_desc: "Thank you, don't forget to re-order!",
  tracking_review: 'Leave review',
  tracking_reviewed: 'Reviewed',
  tracking_order_details: 'Order Details',
  pickup_or_delivered: 'Pickup or delivered',
  pickup: 'Pickup',
  delivered: 'Delivered',
  to_pickup: 'To Pickup',
  update: 'Update',
  update_order_status: 'Update order status',
  order_status_updated: 'Order status updated',
  select_all: 'Select All',
  all_status: 'All status',
  promo_available: 'Promo available',
  successfully_cancelled: 'Cancelled successfully!',
  added_to_cart: 'Added to cart!',
  table_row_changed: 'Table row successfully changed!',
  table_row_failed: 'Failed to change table row!',
  failed_fetch_data: 'Fetch data failed',
  cant_use_promo_code: "Can't use promo code",
  review_thanks: "Thanks for your review, don't forget to buy again!",
  review_failed: 'Review failed',
  waiting_update_status: 'Waiting status updated',
  total_order: 'Total order',
  total_payment: 'Total Payment',
  round_price: 'Round price',
  waiting_for_payment: 'Payment will be cancelled in',
  newest: 'Newest',
  payment: 'Payment',
  qris: 'QRIS',
  platform_fee: 'Platform fee',
  tax_fee: 'Tax Fee',
  service_fee: 'Service Fee',
  reason_cancellation: 'Reason for cancellation',
  table_row: 'Table row',
  edit_table_row: 'Edit table row',
  insert_table_row: 'Insert table row',
  want_to_change_table_row: 'Want to change table row?',
  where_you_sitting: 'Where are you sitting?',
  input_table_row:
    'Input table row where you sit, so tenant can deliver your order easily.',
  set_table: 'Set table row',
  payment_method: 'Payment Method',
  select_payment_method: 'Select Payment Method',
  select: 'Select',
  qris_scan: 'QRIS Scan',
  ewallet: 'E-wallet',
  va: 'Virtual Account Transfer',
  verification_code_desc:
    "You're half way there! Fill with verification code that has been sent to WhatsApp",
  register_desc:
    'Hello, before you continue, please enter your WhatsApp number below to register',
  login_desc:
    'Hello, before you continue, please enter your WhatsApp number below to login',
  login_or_register_desc:
    'Hello, before you continue, please enter your WhatsApp number below to login or register',
  error_resend_otp: 'Please wait before can resend OTP',
  transaction_pending: 'Please complete the payment first',
  transaction_success: 'Transaction Successful',
  enter_new_pin: 'Enter your new PIN',
  enter_pin: 'Enter your PIN',
  confirm_new_pin: 'Confirm your new PIN',
  confirm_pin_not_match: 'Confirm PIN not match',
  enter_table_row: 'Please enter the table row',
  payment_method_required: 'Select payment method first',
  edit_account: 'Edit Account',
  edit_phone: 'Edit WhatsApp Number',
  transfer_to: 'Transfer to:',
  update_profile: 'Update Profile',
  dine_in: 'Dine In',
  take_away: 'Take Away',
  please_rescan_qr: 'InstituteId expired, please rescan QR Code',
  tenant_closed: 'Tenant closed temporary',
  sorry: 'Sorry...',
  under_maintenance_sorry: 'Under Maintenance',
  service_closed: 'Currently, we do not serve orders via Inkanteen 🙏🏻',
  service_maintenance: "Hey sorry, we're currently improving our system :(",
  please_wait: 'Just for a moment, please!',
  under_maintenance: 'The website is under maintenance',
  under_maintenance_thankyou: 'Thank you, see you soon',
  order_type: 'Order Type',
  please_select_valid_date: 'Please select valid date',
  please_select_valid_hour: 'Please select valid hour',
  please_select_valid_minute: 'Please select valid minute',
  additional_menu: 'Additional Menu',
  addon_menu: 'Addons Menu:',
  add_another_items: 'Add another items',
  required: 'Required',
  cash: 'Cash',
  cash_payment_info:
    '*Payment using cash is only available for direct orders and in one tenant only',
  copy: 'Copy',
  copied: 'Copied',
  copy_to_clipboard: 'Copy to clipboard',
  copy_to_clipboard_success: 'copied to clipboard',
  copy_to_clipboard_failed: 'Failed to copy to clipboard',
  please_select_institute: 'Please select the institution first',
  login_or_register_desc:
    'Hello, before you continue, please select the institution below',

  coming_soon: 'Coming soon.. 🙏🏻',
  pageNotFound: 'Page not found',
  pageNotFoundDesc: 'Oops, looks like the page you are looking for is not here',
  pageNotFoundSubDesc: '(error 404)',
  pleaseChooseItem: 'Please choose your item before checkout',
  maxCartNotes: 'Notes cannot exceed 255 characters',
  item: 'item',

  privacySentence1:
    'This privacy policy (“Privacy Policy”) will explain how PT Kanovasi InMaya Nusantara and PT Kiraket Technology Solutions and each of their subsidiaries and affiliated companies (“We”), obtain, collect, use, display, publish, disclose, process, provide access store, transmit, provide, transfer, process, manage, destroy and protect personal information and data (collectively, the “Use”) that you as a user (“you”) of the Platform (as defined below) provide as requested or when using the Platform (“Personal Data”). It should be noted that Personal Data here does not include Personal Data that is already available in the public domain.',
  privacySentence2:
    'This Privacy Policy forms part of our Terms and Conditions of Use (“Terms of Use”). Use of the Platform and any features and/or services available on the Platform (the “Services”) is a form of your agreement to these Terms of Use and Privacy Policy. Therefore, you need to read this Privacy Policy carefully to ensure that you fully understand it before registering, accessing and/or using our Platform and Services.',
  privacyScopeTitle: 'Scope of Privacy Policy',
  privacyScope1: 'This Privacy Policy governs the Use of Personal Data.',
  privacyScope2:
    'By using the Platform, you are deemed to have read this Privacy Policy and agree to the mechanism for the Utilization of your Personal Data as regulated in this Privacy Policy.',
  privacyScope3:
    'If we ask you to provide information when using the Platform, then that information will only be used for the purposes of providing services in accordance with this Privacy Policy.',
  privacyScope4:
    'We may change, delete and apply new terms in this Privacy Policy. You are expected to check this Privacy Policy page periodically for any changes. By using the Platform after such changes occur, you are deemed to have known and agreed to the changes to the terms of this Privacy Policy.',
  privacyRegistrationTitle: 'Registration',
  privacyRegistration1:
    'You are required to register on the Platform to be able to use the features on the Platform.',
  privacyRegistration2:
    'To register on the Platform, you must provide the information we need as stated in Point C (Personal Data) below.',
  privacyPersonalDataTitle: 'Personal Data',
  privacyPersonalData1:
    'You acknowledge and agree that we collect personal information provided by you when you create an account and profile or when using the features contained in the Platform.',
  privacyPersonalData2:
    'Information regarding personal identification that must be filled in by you when creating an account on the Platform includes:',
  privacyPersonalData2Sub1:
    'Full name according to a valid identity card (KTP or Passport); and',
  privacyPersonalData2Sub2:
    'Mobile WhatsApp number, whether connected to WhatsApp or not.',
  privacyPersonalData3:
    'You can enable or disable location recognition services while you are using the Platform.',
  privacyPersonalData4:
    'If necessary, we can verify directly to you about the personal data that you have submitted through the Platform.',
  privacyPersonalData5: 'The information you provide is accurate and true.',
  privacyVisitorDataTitle: 'Visitor Data',
  privacyVisitorDataSentence:
    'You acknowledge and agree that the Platform will collect information about usage, such as (but not limited to) number of visits, source of visitors and visitor activity. We collect this data and we use it with the aim of increasing your satisfaction and our services.',
  privacyVisitorData1:
    'We and our Service Providers may use your Personal Data for business and operational activities in accordance with the purposes for which the Personal Data was provided, including to:',
  privacyVisitorData1Sub1:
    'Provide information to you and the Service Providers at your request relating to our Services.',
  privacyVisitorData1Sub2:
    'Develop, improve and provide products and services that suit your needs.',
  privacyVisitorData1Sub3: 'Respond to your questions, comments and feedback.',
  privacyVisitorData1Sub4:
    'Provide information/send e-mails and/or SMS containing promotions periodically to you if any an offer and/or promotion of new products, special offers, or other information that We think You may need.',
  privacyVisitorData1Sub5:
    'Use and disclose this information when necessary for medical review, legal services, and audits, including fraud and abuse detection and adjustment programs, as well as business planning and management.',
  privacyVisitorData2:
    'We guarantee that the data and information provided by you is confidential and will not be shared except for the things that have been stated and agreed by you in this Privacy Policy. To prevent unauthorized access, We carry out physical, electronic and managerial procedures to protect your information.',
  privacyVisitorData3:
    'Your health information will only be used in connection with Platform services and the things that have been listed and you agree in this Privacy Policy and we will only collect information needed to run services on the Platform.',
  privacyOtherPlatformsTitle: 'Your Connection to Other Platforms',
  privacyOtherPlatformsSentence:
    'The Platform may contain links to third party owned platforms (“Third Party Platforms”) and third party content (“Third Party Content”). For your security, you need to carefully study and read the personal information handling policies that apply to Third Party Platforms and/or Third Party Content.',
  privacyLawTitle: 'Applicable Law',
  privacyLawSentence:
    'This Privacy Policy is governed by the laws of the Republic of Indonesia and you are required to comply with all applicable regulations in the Republic of Indonesia.',
  privacySecurityTitle: 'Security Measures',
  privacySecurity1:
    'We will try to ensure that the information you provide to us is safe and cannot be used by irresponsible parties. For the security of your data, we strongly recommend that you always keep your Platform and software up to date and not disclose your password to any party.',
  privacySecurity2:
    'You hereby agree that We may store Personal Data on a server located in a data center designated by Us. The use of Personal Data in connection with the use of the Platform will continue to be regulated by this Privacy Policy in accordance with the prevailing laws and regulations in the Republic of Indonesia.',
  privacyContactTitle: 'Questions, complaints and contacts',
  privacyContactSentence:
    'If you have any questions about this privacy policy, our rules, your rights, and your relationship with the Platform, please email us at:',
}

// export default en
