<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          LAYANAN KAMI
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solusi untuk Rantai Pasok
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Lacak data yang Anda perlukan untuk terus mengungguli pertumbuhan perusahaan Anda.
        </p>
        <p class="mt-2 text-xs text-gray-400">
          Dapatkan wawasan terbaru melalui platform kami dan selesaikan rencana
        </p>
        <p class="mt-2 text-xs text-gray-400">implementasi Anda secara efektif.</p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            APA YANG KAMI TAWARKAN
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'

export default {
  name: 'ServiceSolutionForSupplyChainPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Informasi Waktu Nyata yang Dapat Ditindaklanjuti',
        description:
          'Saluran Tradisional yang terdiri dari ratusan ribu toko kecil sejauh ini merupakan saluran terbesar Anda, namun sangat sulit untuk memperoleh informasi rantai pasokan yang dapat ditindaklanjuti dan tepat waktu dari saluran tersebut. Solusi Kiraket untuk Rantai Pasokan memberi Anda angka data penjualan dan inventaris waktu nyata untuk melacak penjualan harian, ketersediaan di dalam toko, dan efektivitas saluran secara keseluruhan berdasarkan saluran distribusi baik langsung maupun tidak langsung. Jadikan perencanaan permintaan, perencanaan distribusi, dan ketersediaan di toko Anda jauh lebih efektif dengan menggunakan data dan perangkat analitis prediktif dan historis kami yang unik.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'Keunggulan Kiraket',
        description:
          'Kami mengumpulkan dan melacak data penjualan real-time dari puluhan ribu pengecer tradisional dan membuat informasi ini tersedia bagi Anda untuk dikonsumsi secara real-time baik melalui tautan ke solusi analitik yang ada atau menggunakan wawasan dan meja kerja analitik kami. Platform unik kami memungkinkan Anda tidak hanya menganalisis data masa lalu tetapi juga terhubung secara real-time dengan pengecer kecil untuk melakukan eksperimen, survei, dan analisis sentimen secara real-time. Dapatkan umpan balik dan informasi yang Anda perlukan secara real-time untuk membuat pemasaran Anda jauh lebih efektif dan menghasilkan laba atas belanja pemasaran yang jauh lebih tinggi.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Pemimpin Rantai Pasok',
        description:
          'Pantau efektivitas rantai pasokan Anda hingga ke titik terakhir dari saluran tradisional. Gunakan data dari Kiraket untuk merencanakan dan mengelola aktivitas Rantai Pasokan Anda secara lebih efektif guna meningkatkan tingkat layanan di tingkat toko dan menurunkan biaya.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'Pemimpin S&OP (sales & operations planning)',
        description:
          'Gunakan data dari platform Kiraket untuk membuat perencanaan S&OP Anda jauh lebih efektif dengan memanfaatkan tren dan data aktual di platform Kiraket untuk merencanakan keputusan penawaran/permintaan, kampanye promosi, dan peluncuran produk baru secara lebih efektif.',
        icon: 'USER',
      },
      {
        id: 3,
        title: 'Manajer Distribusi',
        description:
          'Data inventaris dan kecepatan penjualan Kiraket dapat digunakan untuk merencanakan dan mengelola saluran distribusi dan aset Anda secara lebih efektif guna memastikan tingkat layanan tertinggi dengan biaya terendah. Kelola mitra saluran Anda secara efektif atau lihat opsi pengiriman langsung baru dengan menggunakan data waktu nyata kami untuk merencanakan, melakukan simulasi, dan bertindak secara efektif.',
        icon: 'USER',
      },
      {
        id: 4,
        title: 'Manajer Transportasi',
        description:
          'Gabungkan data kami ke dalam perencanaan transportasi Anda untuk memprioritaskan dan menjadwalkan rute dengan lebih baik, memanfaatkan peluang logistik terbalik, dan mengelola transportasi pengemasan yang dapat dikembalikan dengan lebih baik.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
