<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col pb-10 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="mb-8 flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          CARI TAHU
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Visi dan Misi Kami
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Kami percaya pada cara yang lebih efisien untuk membuat data real-time
          dari pasar ritel tradisional.
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Dengan aliran data yang besar, perusahaan dapat mengembangkan dan
          menyempurnakan produk mereka lebih cepat dari sebelumnya.
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-40"></div>
      </div>
    </div>
    <div class="mb-20 flex w-full flex-col">
      <div
        class="vision-mission flex items-center bg-cover bg-center py-12 md:py-24 lg:py-32 xl:py-56"
      >
        <div
          class="z-20 flex w-full flex-col items-center px-6 py-10 md:py-20 lg:px-56"
        >
          <p class="md:text-md self-center text-xl text-white md:text-2xl">
            Tujuan dan Nilai Kami
          </p>
          <p
            class="mt-3 text-center text-xs leading-6 text-white md:mt-8 md:text-base md:leading-10"
          >
            Kiraket adalah perusahaan teknologi digital yang dibangun untuk
            menciptakan solusi bagi pasar ritel tradisional. Selama beberapa
            dekade telah terbukti bahwa pasar ritel tradisional telah
            mendominasi sebagian besar pasar ritel, namun solusi yang ada masih
            kurang dalam memperoleh wawasan tentang analisis produk konsumen di
            pasar tradisional. Karena cara manual, data tradisional tidak dapat
            menyediakan data mengenai penjualan, inventaris, dan statistik
            keseluruhan. Inilah yang Kiraket ingin selesaikan dengan platform
            kami. Kami menciptakan teknologi untuk mendapatkan data ritel
            tradisional untuk menyediakan produk konsumen bagi perusahaan. Kami
            tidak hanya akan mendapatkan data produk secara real-time tetapi
            kami juga dapat dengan mudah mengakses komunitas ritel tradisional
            untuk berkomunikasi dengan mereka mengenai masukan dari produk.
            Kiraket bertujuan untuk melengkapi ritel tradisional dengan platform
            teknologi modern yang membantu mereka menjadi lebih berorientasi
            pada permintaan, bersaing, dan tumbuh di dunia yang berubah dengan
            cepat.
          </p>
        </div>
      </div>
    </div>

    <div
      class="container flex flex-col px-2 pb-10 pt-10 lg:mx-auto lg:px-20 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <QuoteIcon class="w-12" />
        <p
          class="md:text-md mt-12 self-center text-xl italic text-black md:text-2xl"
        >
          “The only way to discover the limits of the possible is to go beyond
          them into the impossible.”
        </p>
        <p
          class="md:text-md mt-4 self-center text-xl italic text-black md:text-2xl"
        >
          –Arthur C. Clarke
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import QuoteIcon from '@/assets/svg/quote.svg'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: { QuoteIcon },
  mixins: [globalMixin],
  data: () => ({}),
  methods: {},
}
</script>

<style scoped>
.vision-mission {
  background-image: url('@/assets/image/vision_mission.png');
  background-size: cover;
  box-shadow: inset 0 0 0 2000px #051b1695;
}
</style>
