<template>
  <div>
    <AppLayout>
      <router-view
        :key="$route.fullPath"
        v-slot="{ Component }"
        @show-loading-indicator="showGlobalLoadingIndicator"
      >
        <transition name="moveInUp">
          <component :is="Component" />
        </transition>
      </router-view>
    </AppLayout>
    <LoadingIndicator v-if="isLoading" />
  </div>
</template>

<script>
import { computed, onMounted, ref, reactive, toRefs, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import globalMixin from '@/mixins/global.js'
import AppLayout from '@/layouts/layout.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'

export default {
  name: 'App',
  components: {
    AppLayout,
    LoadingIndicator,
  },
  mixins: [globalMixin],

  provide() {
    return {
      message: computed(() => this.message),
    }
  },

  setup() {
    let isLoading = ref(false)

    const { t, n } = useI18n()

    const scrollTop = ref('0')

    const showGlobalLoadingIndicator = (state) => {
      isLoading.value = state
    }

    return {
      t,
      n,
      scrollTop,
      isLoading,
      showGlobalLoadingIndicator,
    }
  },
  data() {
    return {
      message: 'hello',
    }
  },
}
</script>
