<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          LAYANAN KAMI
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solusi untuk Pemasaran
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Membentuk koneksi yang lebih baik untuk menjangkau pelanggan dan kebutuhan konsumen dengan lebih cepat, efektif,
        </p>
        <p class="mt-2 text-xs text-gray-400">
          dan mencapai laba atas investasi pemasaran yang jauh lebih tinggi
        </p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            APA YANG KAMI TAWARKAN
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferPricing v-if="item.icon === 'PRICING'" class="h-10" />
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
            <OfferStore v-if="item.icon === 'STORE'" class="h-10" />
            <OfferSearch v-if="item.icon === 'SEARCH'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferPricing from '@/assets/svg/offers/price-coin.svg'
import OfferUser from '@/assets/svg/offers/user.svg'
import OfferStore from '@/assets/svg/offers/store.svg'
import OfferSearch from '@/assets/svg/offers/search.svg'

export default {
  name: 'ServiceSolutionForMarketingPage',
  components: {
    CardTwo,
    CardThree,
    OfferPricing,
    OfferUser,
    OfferStore,
    OfferSearch,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Tingkatkan Pemasaran Anda',
        description:
          'Meskipun saluran tradisional sangat penting, saluran ini sangat sulit diperoleh - sampai sekarang! Solusi pemasaran Kiraket memungkinkan Anda memperoleh informasi real-time untuk meningkatkan inisiatif pemasaran Anda dan memperoleh laba atas investasi pemasaran yang jauh lebih tinggi.',
      },
      {
        id: 2,
        title: 'Informasi Waktu Nyata yang Dapat Ditindaklanjuti',
        description:
          'Saluran Tradisional yang terdiri dari ratusan ribu toko kecil sejauh ini merupakan saluran terbesar Anda, namun sangat sulit untuk memperoleh informasi pemasaran yang dapat ditindaklanjuti dan tepat waktu dari saluran tersebut. Solusi pemasaran Kiraket memungkinkan Anda memperoleh informasi real-time untuk meningkatkan inisiatif pemasaran Anda dan memperoleh laba atas investasi pemasaran yang jauh lebih tinggi.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'Kelebihan Kiraket',
        description:
          'Kami mengumpulkan dan melacak data penjualan real-time dari puluhan ribu pengecer tradisional dan membuat informasi ini tersedia bagi Anda untuk dikonsumsi secara real-time baik melalui tautan ke solusi analitik yang ada atau menggunakan wawasan dan meja kerja analitik kami. Platform unik kami memungkinkan Anda tidak hanya menganalisis data masa lalu tetapi juga terhubung secara real-time dengan pengecer kecil untuk melakukan eksperimen, survei, dan analisis sentimen secara real-time. Dapatkan umpan balik dan informasi yang Anda perlukan secara real-time untuk membuat pemasaran Anda jauh lebih efektif dan menghasilkan laba atas belanja pemasaran yang jauh lebih tinggi.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Manajemen Eksekutif',
        description:
          'Dapatkan gambaran menyeluruh tentang kinerja organisasi Anda di seluruh saluran utama ini. Lihat kinerja merek dan tren pangsa pasar, jangkauan dan proliferasi produk, dan banyak lagi untuk membantu Anda mengambil keputusan paling penting.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'Strategi Penetapan Harga',
        description:
          'Manfaatkan analisis tingkat lanjut pada variasi harga di wilayah mikro dan lihat dengan cepat korelasi dan tren antara harga dan penjualan. Gunakan data untuk mengembangkan strategi penetapan harga tingkat lanjut, merespons perubahan harga kompetitif dengan cepat, dan menggunakan leverage platform untuk melakukan pengoptimalan harga tingkat lanjut.',
        icon: 'PRICING',
      },
      {
        id: 3,
        title: 'Manajer Kampanye',
        description:
          'Jadikan kampanye promosi, produk baru, dan periklanan Anda jauh lebih efektif dengan umpan balik real-time mengenai pelaksanaan dan efektivitas ritel. Memanfaatkan kekuatan platform Kiraket untuk melakukan kampanye mikro dan penelitian yang efektif dan menguntungkan.',
        icon: 'PRICING',
      },
      {
        id: 4,
        title: 'Manajer Merek / Kategori',
        description:
          'Lacak kinerja merek di berbagai aspek termasuk pangsa pasar/pangsa dompet berdasarkan geografi dan demografi. Pantau efektivitas kampanye Anda secara real-time dan lihat serta bereaksi dengan cepat ketika persaingan bergerak di pasar.',
        icon: 'STORE',
      },
      {
        id: 5,
        title: 'Manajer Riset Pasar',
        description:
          'Tingkatkan riset pasar dan analisis sentimen pelanggan Anda dengan memanfaatkan kekuatan penuh platform Kiraket untuk melakukan penelitian presisi dan mendapatkan wawasan hampir real-time.',
        icon: 'SEARCH',
      },
      {
        id: 6,
        title: 'Kepala Pemasaran',
        description:
          'Lacak kinerja pemasaran Anda dan dapatkan data real-time tentang KPI terpenting Anda seperti ROI dan kampanye, Pengenalan Produk Baru, dan Penetapan Harga.',
        icon: 'USER',
      },
    ],
  }),
  methods: {},
}
</script>
