<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          LAYANAN KAMI
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Solusi untuk Penjualan
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Dapatkan wawasan mendetail tentang data penjualan produk Anda
        </p>
        <p class="mt-2 text-xs text-gray-400">
          yang belum pernah ada sebelumnya dengan data nyata langsung dari pasar ritel tradisional.
        </p>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/home-1.png" class="w-full rounded-xl" />
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informations" :key="item.id" class="flex w-full">
          <CardThree :title="item.title" :description="item.description" />
        </div>
      </div>

      <!-- Offers -->
      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            APA YANG KAMI TAWARKAN
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col flex-wrap px-6 md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/2 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferUser v-if="item.icon === 'USER'" class="h-10" />
            <OfferSearch v-if="item.icon === 'SEARCH'" class="h-10" />
            <OfferNewspaper v-if="item.icon === 'NEWSPAPER'" class="h-10" />
            <OfferLayer v-if="item.icon === 'LAYER'" class="h-10" />
          </CardTwo>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col px-6 lg:px-32">
        <div v-for="item in informationsTwo" :key="item.id" class="flex w-full">
          <CardThree
            :title="item.title"
            :description="item.description"
            :show-icon="false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import CardTwo from '@/components/card/CardTwo.vue'
import CardThree from '@/components/card/CardThree.vue'

// Offers
import OfferUser from '@/assets/svg/offers/user.svg'
import OfferSearch from '@/assets/svg/offers/search.svg'
import OfferNewspaper from '@/assets/svg/offers/newspaper.svg'
import OfferLayer from '@/assets/svg/offers/layer.svg'

export default {
  name: 'ServiceSolutionForSalesPage',
  components: {
    CardTwo,
    CardThree,
    OfferUser,
    OfferSearch,
    OfferNewspaper,
    OfferLayer,
  },
  mixins: [globalMixin],
  data: () => ({
    informations: [
      {
        id: 1,
        title: 'Informasi Waktu Nyata yang Dapat Ditindaklanjuti',
        description:
          'Saluran Tradisional yang terdiri dari ratusan ribu toko kecil sejauh ini merupakan saluran terbesar Anda, namun sangat sulit untuk memperoleh informasi penjualan yang dapat ditindaklanjuti dan tepat waktu dari saluran tersebut. Solusi Kiraket untuk penjualan memberi Anda angka data penjualan dan inventaris waktu nyata untuk melacak penjualan harian, ketersediaan di dalam toko, dan efektivitas saluran secara keseluruhan baik untuk merek maupun pesaing Anda. Selain itu, platform online kami memungkinkan pesan penjualan dipromosikan ke audiens yang ditargetkan secara tepat.',
      },
    ],
    informationsTwo: [
      {
        id: 1,
        title: 'Kelebihan Kiraket',
        description:
          'Kami mengumpulkan dan melacak data penjualan real-time dari puluhan ribu pengecer tradisional dan membuat informasi ini tersedia bagi Anda untuk dikonsumsi secara real-time baik melalui tautan ke solusi analitik yang ada atau menggunakan wawasan dan meja kerja analitik kami. Platform unik kami memungkinkan Anda tidak hanya menganalisis data masa lalu tetapi juga terhubung secara real-time dengan pengecer kecil untuk melakukan eksperimen, survei, dan analisis sentimen secara real-time. Dapatkan umpan balik dan informasi yang Anda perlukan secara real-time untuk membuat pemasaran Anda jauh lebih efektif dan menghasilkan laba atas belanja pemasaran yang jauh lebih tinggi.',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Manajemen Eksekutif',
        description:
          'Lacak penjualan dan tren penjualan di seluruh portofolio Anda untuk memungkinkan pengambilan keputusan eksekutif yang cepat dan efektif. Gunakan data dan analitik kami untuk melakukan aktivitas simulasi dan perencanaan yang mendalam.',
        icon: 'USER',
      },
      {
        id: 2,
        title: 'Manajemen penjualan',
        description:
          'Lacak kinerja penjualan Anda dan ukur serta nilai efektivitas strategi pelaksanaan penjualan Anda dan dapatkan umpan balik waktu nyata untuk mengelola tim penjualan Anda secara lebih efektif.',
        icon: 'NEWSPAPER',
      },
      {
        id: 3,
        title: 'Eksekusi Penjualan',
        description:
          'Manfaatkan wawasan dari platform Kiraket untuk menciptakan strategi eksekusi penjualan yang sempurna. Manfaatkan kekuatan platform untuk memahami kecepatan penjualan, analisis wilayah terperinci, penempatan produk, ketersediaan barang dagangan POS, dan banyak lagi.',
        icon: 'SEARCH',
      },
      {
        id: 4,
        title: 'Pengelolaan Permintaan',
        description:
          'Tingkatkan akurasi dan presisi manajemen permintaan Anda dengan menggunakan kekuatan teknologi terkini di platform Kiraket. Dari penginderaan permintaan waktu nyata hingga analisis prediktif berdasarkan informasi sebab akibat yang terperinci dan relevan mengenai pengaruh aktivitas promosi, produk baru, dan penjualan Anda dan pesaing.',
        icon: 'LAYER',
      },
    ],
  }),
  methods: {},
}
</script>
