<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-20"
    >
      <div class="flex w-full flex-col-reverse px-6 lg:flex-row lg:px-0">
        <div
          class="flex w-full flex-col pr-0 pt-12 md:space-y-2 md:pr-10 lg:w-1/2 lg:pt-0"
        >
          <p class="text-sm text-gray-400 md:text-base">KIRAKET</p>
          <div class="hidden space-y-0 lg:flex lg:flex-col lg:space-y-4">
            <p class="text-2xl text-black lg:text-4xl">
              Kembangkan bisnis anda
            </p>
            <p class="text-2xl text-black lg:text-4xl">tersistemisasi</p>
            <p class="text-2xl text-black lg:text-4xl">bersama kami</p>
          </div>
          <div class="flex lg:hidden">
            <p class="text-2xl text-black">
              Kembangkan bisnis anda tersistemisasi bersama kami
            </p>
          </div>
          <div class="flex w-full flex-col md:flex-row md:items-center">
            <div class="relative flex items-center">
              <CircleLines />
              <div class="absolute">
                <Button title="Mari Bergabung">
                  <ArrowRightIcon class="ml-2 h-3 text-white" />
                </Button>
              </div>
            </div>
            <div class="mt-4 flex md:mt-0 md:pl-8">
              <p
                class="text-kira-primary cursor-pointer text-sm underline underline-offset-4 md:text-base"
                @click="openRoute('partnership')"
              >
                Lihat Rencana Kemitraan
              </p>
            </div>
          </div>
        </div>
        <div class="flex w-full lg:w-1/2">
          <div class="relative flex w-full items-end">
            <img
              src="@/assets/image/home-transparent.png"
              class="max-h-min w-full rounded-xl"
            />
            <!-- <div
              class="bg-kira-primary absolute -ml-5 -mb-6 h-12 w-12 rounded-lg shadow-xl md:h-20 md:w-20 lg:-mb-6"
            ></div> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="container flex w-full flex-col pb-20 lg:mx-auto lg:flex-row lg:px-0"
    >
      <div class="flex w-full lg:w-5/12 lg:pr-20">
        <div class="-mt-2 w-full">
          <img src="@/assets/image/dashboard.png" class="w-full" />
        </div>
      </div>
      <div
        class="mt-10 flex w-full flex-col px-6 pr-4 pb-10 md:pr-6 md:pb-0 lg:mt-0 lg:w-7/12 lg:px-0 lg:pr-20 xl:pr-24"
      >
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          BERGABUNG BERSAMA KAMI
        </p>
        <div class="mt-4 hidden space-y-3 lg:flex lg:flex-col">
          <p class="text-xl text-black md:text-2xl">
            Wawasan yang Mengubah Permainan untuk
          </p>
          <p class="text-xl text-black md:text-2xl">
            Pemasaran, Penjualan & Rantai Pasok
          </p>
        </div>
        <div class="mt-4 flex lg:hidden">
          <p class="text-xl text-black md:text-2xl">
            Wawasan yang Mengubah Permainan untuk Pemasaran, Penjualan & Rantai
            Pasok
          </p>
        </div>
        <div class="mt-4 flex flex-col">
          <p class="text-sm leading-8 text-gray-400">
            <span class="text-kira-secondary">PEMENANG</span> di era digital
            saat ini adalah organisasi-organisasi yang paling mampu memahami dan
            mengambil wawasan dari seluruh basis pelanggan mereka. Kiraket
            adalah satu-satunya platform yang menawarkan data real-time dan
            komprehensif dari Saluran Ritel Tradisional
          </p>
          <p class="mt-3 text-sm leading-8 text-gray-400">
            Bergabunglah dengan kami sebagai mitra prioritas awal untuk
            mendapatkan akses istimewa:
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Akses eksklusif ke data dan wawasan
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Diskon untuk iklan dan promosi
          </p>
          <p class="text-sm leading-8 text-gray-400">
            - Masukan eksklusif mengenai pengembangan platform
          </p>
          <!-- <p class="mt-3 text-sm leading-8 text-gray-400">
            Klik di bawah untuk mempelajari lebih lanjut tentang bagaimana program kemitraan akses awal kami dapat memberikan manfaat signifikan bagi bisnis Anda dalam beberapa bulan dan tahun mendatang!
          </p> -->
        </div>

        <!-- <div class="mt-4 flex w-full justify-end">
          <div
            class="flex cursor-pointer flex-row items-center justify-end pr-4 md:pr-0"
          >
            <BorderLines />
            <p class="text-kira-secondary mx-2 text-sm leading-8">Pelajari Lebih Lanjut</p>
            <ArrowRightIcon class="ml-2 h-3 text-gray-700" />
          </div>
        </div> -->
      </div>
    </div>

    <div class="container flex flex-col px-6 pb-20 lg:mx-auto lg:pb-32">
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          LAYANAN KAMI
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Ekspektasi pengecer terus meningkat.
        </p>
        <p class="md:text-md mt-2 self-center text-xl text-black md:text-2xl">
          Bersiaplah dengan solusi untuk rantai pasok dan tim penjualan.
        </p>

        <p class="mt-4 text-xs text-gray-400">
          Informasi adalah kunci untuk memahami dan membangun jalur terbaik
          untuk mengambil tindakan demi masa depan perusahaan.
        </p>
        <p class="mt-2 text-xs text-gray-400">
          Jadilah yang pertama dalam perlombaan dengan platform kami.
        </p>
      </div>

      <!-- Services -->
      <div class="mt-10 flex w-full flex-col text-center md:flex-row lg:px-32">
        <div
          v-for="item in services"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 lg:p-8"
        >
          <CardOne
            :title="item.title"
            :description="item.description"
            @on-click="openRoute(item.routeName)"
          >
            <ServiceForSales v-if="item.icon === 'FOR_SALES'" class="h-20" />
            <ServiceForMarketing
              v-if="item.icon === 'FOR_MARKETING'"
              class="h-20"
            />
            <ServiceForSupplyChain
              v-if="item.icon === 'FOR_SUPPLY_CHAIN'"
              class="h-20"
            />
          </CardOne>
        </div>
      </div>

      <div class="mt-20 flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          Kami Memberikan Layanan Dengan Fitur - Fitur Unggulan
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Kami menyediakan layanan yang terbaik untuk Anda.
        </p>
      </div>

      <!-- Services System -->
      <div
        class="mt-10 mb-10 flex flex-col text-center md:grid md:grid-cols-3 md:gap-8 lg:px-32"
      >
        <div
          v-for="item in servicesSystem"
          :key="item.id"
          class="flex p-2 lg:p-2"
        >
          <CardOne
            :title="item.title"
            :description="item.description"
            class="h-full w-full"
            @on-click="openRoute(item.routeName)"
          >
            <UserGroupIcon
              v-if="item.icon === 'HRD'"
              class="text-kira-primary h-12"
            />
            <BanknotesIcon
              v-if="item.icon === 'PAYROLL'"
              class="text-kira-primary h-12"
            />
            <ShoppingBagIcon
              v-if="item.icon === 'PURCHASE_ORDER'"
              class="text-kira-primary h-12"
            />
            <CalculatorIcon
              v-if="item.icon === 'SALES_ORDER'"
              class="text-kira-primary h-12"
            />
            <WalletIcon
              v-if="item.icon === 'KEUANGAN'"
              class="text-kira-primary h-12"
            />
            <CircleStackIcon
              v-if="item.icon === 'STOK'"
              class="text-kira-primary h-12"
            />
            <MagnifyingGlassCircleIcon
              v-if="item.icon === 'PELACAKAN'"
              class="text-kira-primary h-12"
            />
            <QueueListIcon
              v-if="item.icon === 'REQUEST'"
              class="text-kira-primary h-12"
            />
          </CardOne>
        </div>
      </div>

      <div class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:pb-16">
        <div class="flex w-full flex-col items-center text-center">
          <p class="text-sm tracking-widest text-gray-400 md:text-base">
            APA YANG KAMI TAWARKAN
          </p>
        </div>
      </div>

      <!-- Offers -->
      <div class="flex w-full flex-col md:flex-row lg:px-32">
        <div
          v-for="item in offers"
          :key="item.id"
          class="flex w-full p-2 md:w-1/3 lg:p-8"
        >
          <CardTwo :title="item.title" :description="item.description">
            <OfferSalesMeasurement v-if="item.icon === 'SALES'" class="h-10" />
            <OfferInStoreAvailability
              v-if="item.icon === 'AVAILABILITY'"
              class="h-10"
            />
            <OfferPricePromotion
              v-if="item.icon === 'PRICE_PROMOTION'"
              class="h-10"
            />
          </CardTwo>
        </div>
      </div>

      <div id="mitra" class="my-16 text-center">
        <div class="mx-auto max-w-lg py-16">
          <p class="text-xl text-black md:text-2xl">Mitra Kami</p>

          <p class="mt-4 text-xs text-gray-400 md:text-sm">
            Kami menyediakan solusi untuk berbagai perusahaan, baik itu
            perusahaan besar maupun perusahaan kecil.
          </p>
        </div>

        <div class="mx-auto max-w-screen-xl pb-16">
          <div class="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div
              class="col-span-2 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/B/bkkbn-logo-0E947E891C-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-2 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/N/ninja-xpress-logo-860168EFBE-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-2 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Q7S8006z9gIgGM-5H2VW3gBh0-2Hp_w8UbB9yWP4&s"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-2 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/C/ciputra-logo-932379C5C5-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-2 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/N/nissin-foods-logo-06652B60C7-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/G/gudang-garam-logo-0F53658D7E-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://images.bisnis.com/posts/2023/02/22/1630863/kementerian-lingkungan-hidup.jpg"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://seeklogo.com/images/T/toyota-logo-08A29AEE08-seeklogo.com.png"
                class="h-12"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://images.glints.com/unsafe/glints-dashboard.s3.amazonaws.com/company-logo/7037940a4dac03df49f793c49d7a8b28.jpg"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://www.solutiva.co.id/wp-content/uploads/2020/09/13a.-Amerta-Indah-Otsuka.png"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Vivo_logo_2019.svg/2560px-Vivo_logo_2019.svg.png"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://media.kbjatim.id/albums/userpics/10001/5/normal_Kementerian_Kesehatan.png"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/f/f4/Sriwijaya_air.png"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://images.glints.com/unsafe/glints-dashboard.s3.amazonaws.com/company-logo/3bdfc7229be843538113e31628ae625c.png"
                class="h-14"
                alt=""
              />
            </div>

            <div
              class="col-span-1 mt-2 flex items-center justify-center md:col-span-3 md:mt-4 lg:col-span-1"
            >
              <img
                src="https://www.otsuka.co.jp/en/app-files/img/cmn_logo02_03.png"
                class="h-14"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <!--  -->
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'
import Button from '@/components/button/Button.vue'
import CardOne from '@/components/card/CardOne.vue'
import CardTwo from '@/components/card/CardTwo.vue'

// Svgs
import ArrowRightIcon from '@/assets/svg/arrow-right.svg'
import CircleLines from '@/assets/svg/circle-lines.svg'
import BorderLines from '@/assets/svg/border-1.svg'
// Services
import ServiceForSales from '@/assets/svg/services/for-sales.svg'
import ServiceForMarketing from '@/assets/svg/services/for-marketing.svg'
import ServiceForSupplyChain from '@/assets/svg/services/for-supply-chain.svg'
// Offers
import OfferSalesMeasurement from '@/assets/svg/offers/discount.svg'
import OfferInStoreAvailability from '@/assets/svg/offers/store.svg'
import OfferPricePromotion from '@/assets/svg/offers/price-coin.svg'

import {
  UserGroupIcon,
  CurrencyDollarIcon,
  BanknotesIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  WalletIcon,
  CalculatorIcon,
  CircleStackIcon,
  MagnifyingGlassCircleIcon,
  QueueListIcon
} from '@heroicons/vue/24/solid'

export default {
  name: 'HomePage',
  components: {
    Button,
    CardOne,
    CardTwo,
    ArrowRightIcon,
    CircleLines,
    BorderLines,
    ServiceForSales,
    ServiceForMarketing,
    ServiceForSupplyChain,
    OfferSalesMeasurement,
    OfferInStoreAvailability,
    OfferPricePromotion,

    UserGroupIcon,
    CurrencyDollarIcon,
    BanknotesIcon,
    ShoppingBagIcon,
    ShoppingCartIcon,
    WalletIcon,
    CalculatorIcon,
    CircleStackIcon,
    MagnifyingGlassCircleIcon,
    QueueListIcon
  },
  mixins: [globalMixin],
  data: () => ({
    services: [
      {
        id: 1,
        title: 'Solusi untuk Penjualan',
        description:
          'Tingkatkan efektivitas penjualan Anda dan tingkatkan pendapatan dengan solusi data dan analitik kami untuk penjualan. Lihat kecepatan penjualan, tingkat inventaris, dan posisi pesaing secara real-time pada tingkat detail yang Anda perlukan.',
        icon: 'FOR_SALES',
        routeName: 'service-solution-for-sales',
      },
      {
        id: 2,
        title: 'Solusi untuk Pemasaran',
        description:
          'Tingkatkan aktivitas pemasaran Anda dengan memanfaatkan kekuatan analisis real-time dan masukan dari saluran terbesar Anda. Umpan balik real-time mengenai pangsa pasar, penjualan, promosi, produk baru, efektivitas periklanan, dan banyak lagi.',
        icon: 'FOR_MARKETING',
        routeName: 'service-solution-for-marketing',
      },
      {
        id: 3,
        title: 'Solusi untuk Rantai Pasok',
        description:
          'Tingkatkan efektivitas Rantai Pasok Anda untuk menurunkan inventaris dan meningkatkan tingkat layanan dengan data inventaris dan penjualan real-time dari segmen pasar terbesar dan paling menguntungkan Anda.',
        icon: 'FOR_SUPPLY_CHAIN',
        routeName: 'service-solution-for-supply-chain',
      },
    ],
    servicesSystem: [
      {
        id: 1,
        title: 'HRD',
        description: 'Mengatur Manajemen Karyawan.',
        icon: 'HRD',
        // routeName: 'service-solution-for-sales',
      },
      {
        id: 2,
        title: 'Daftar Gaji',
        description: 'Mengelola Sistem Penggajian Karyawan.',
        icon: 'PAYROLL',
        // routeName: 'service-solution-for-marketing',
      },
      {
        id: 3,
        title: 'Pembelian',
        description: 'Mengelola Sistem Perbelanjaan Barang.',
        icon: 'PURCHASE_ORDER',
        // routeName: 'service-solution-for-supply-chain',
      },
      {
        id: 4,
        title: 'Penjualan',
        description: 'Mengelola Performa Penjualan dan Reporting Penjualan.',
        icon: 'SALES_ORDER',
        // routeName: 'service-solution-for-supply-chain',
      },
      {
        id: 5,
        title: 'Keuangan',
        description:
          'Mengelola Arus Kas, Modal, Keuntungan, dan Asset Perusahaan.',
        icon: 'KEUANGAN',
        // routeName: 'service-solution-for-supply-chain',
      },
      {
        id: 6,
        title: 'Stok',
        description:
          'Pengelolaan Aset Barang, Stok Bahan Baku, Maupun Penjualan.',
        icon: 'STOK',
        // routeName: 'service-solution-for-supply-chain',
      },
      {
        id: 7,
        title: 'Pelacakan',
        description: 'Mengelola Titik Lokasi Keberadaan Karyawan.',
        icon: 'PELACAKAN',
        // routeName: 'service-solution-for-supply-chain',
      },
      {
        id: 8,
        title: 'Ajukan Fitur',
        description: 'Anda bisa mengajukan Fitur.',
        icon: 'REQUEST',
        // routeName: 'service-solution-for-supply-chain',
      },
    ],
    offers: [
      {
        id: 1,
        title: 'Pengukuran Penjualan',
        description:
          'Gunakan data real-time untuk meningkatkan proses penjualan dan meningkatkan pendapatan. Bandingkan dengan mudah data mendalam dari berbagai geografi dan bahkan waktu dalam sehari.',
        icon: 'SALES',
      },
      {
        id: 2,
        title: 'Ketersediaan Di Toko',
        description:
          'Memprediksi pasokan dan permintaan yang dibutuhkan dengan melihat apa yang dibeli pelanggan dan area mana secara real-time. Dan bersiaplah dengan inventaris di wilayah geografis yang tepat.',
        icon: 'AVAILABILITY',
      },
      {
        id: 3,
        title: 'Pengukuran Harga & Promosi',
        description:
          'Gunakan platform kami, informasi dapat digunakan untuk menguji berbagai strategi penetapan harga dan invensi perdagangan dan untuk dapat dengan cepat mengakses informasi harga komparatif yang kompetitif untuk merespons dengan cepat pergerakan merek dan produk pesaing.',
        icon: 'PRICE_PROMOTION',
      },
    ],
  }),
  methods: {},
}
</script>
