import * as Yup from 'yup'
import { colors } from '../themeConfig'

const phoneRegExp = /^[\+]?[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/
const numberRegExp = /([\d +-]+)$/
const phoneRegExpNoPlus = /^[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/
// /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default {
  inject: ['dayjs'],
  data: () => ({
    appName: 'Kiraket',
    userAgent: '',
    scrollToTop: '0',
    imageBoxUrl: 'https://via.placeholder.com/50x50',
    showModal: false,
    showModalChangeLanguage: false,
    phoneSchema: Yup.object().shape({
      phone_number: Yup.string()
        .matches(phoneRegExp, 'phone_number_not_valid')
        .typeError('phone_number_required')
        .required('phone_number_required'),
    }),
    emailSchema: Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
    }),
    phoneRegExp: phoneRegExp,
    numberRegExp: numberRegExp,
    phoneRegExpNoPlus: phoneRegExpNoPlus,
  }),
  computed: {},
  mounted() {
    this.userAgent = navigator.userAgent
  },
  methods: {
    openRoute(routeName) {
      this.$router.push({
        name: routeName,
        query: { lang: this.$route.query.lang },
      })
    },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    updateThemeConfig(setting) {
      if ('colors' in setting) {
        const colors = setting.colors
        this.$vs.theme({
          primary: colors.primary,
          secondary: colors.secondary,
          success: colors.success,
          warning: colors.warning,
          danger: colors.danger,
          dark: colors.dark,
        })
      }
    },
    resetThemeConfig() {
      this.$vs.theme({
        ...colors,
      })
    },
  },
}
