<template>
  <section id="content" class="flex flex-col">
    <div
      class="container flex flex-col px-2 pb-20 pt-10 lg:mx-auto lg:px-6 lg:pb-32 lg:pt-16"
    >
      <div class="flex w-full flex-col items-center text-center">
        <p class="text-sm tracking-widest text-gray-400 md:text-base">
          INVESTOR
        </p>

        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Menjadi Investor dan menjadi bagian
        </p>
        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          dari Masa Depan Analisis Data
        </p>
        <p class="md:text-md mt-4 self-center text-xl text-black md:text-2xl">
          Ritel Tradisional
        </p>

        <div class="bg-kira-secondary mt-4 h-1 w-32"></div>
      </div>

      <div
        class="container mt-20 flex flex-col px-6 pb-12 lg:mx-auto lg:px-20 lg:pb-16"
      >
        <div class="flex w-full flex-col items-center text-center">
          <img src="@/assets/image/investor.png" class="w-full" />
        </div>
      </div>

      <div class="mb-32 flex w-full flex-col px-6 lg:px-32">
        <div class="flex-center mb-10 flex flex-col justify-start">
          <div class="flex w-full justify-center">
            <p
              class="text-center text-xs tracking-widest text-gray-400 md:text-base"
            >
              METRIK KAMI SEJAUH INI...
            </p>
          </div>
          <div class="mt-12 flex flex-row">
            <div class="flex w-1/4 items-center justify-end">
              <CircleLeft class="h-10 cursor-pointer" />
            </div>
            <div class="flex w-2/4 flex-col items-start justify-center">
              <div class="flex w-full flex-row justify-center">
                <div class="flex w-1/3 items-center justify-end">
                  <Philippines class="h-12 opacity-30" />
                </div>
                <div class="flex w-1/3 items-center justify-center">
                  <Indonesia class="h-28" />
                </div>
                <div class="flex w-1/3 items-center justify-start">
                  <India class="h-12 opacity-30" />
                </div>
              </div>
            </div>
            <div class="flex w-1/4 items-center justify-start">
              <CircleRight class="h-10 cursor-pointer" />
            </div>
          </div>
          <div class="mt-10 flex flex-col items-center justify-center">
            <div class="flex flex-col items-start justify-center">
              <p class="text-kira-secondary text-md leading-8">004 outlet</p>
              <p class="text-kira-secondary text-md leading-8">
                10000 transaksi
              </p>
              <p class="text-kira-secondary text-md leading-8">10 merk</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex w-full flex-col px-6 lg:px-32">
        <div
          class="flex-center mb-10 flex flex-col justify-start transition duration-500 hover:scale-105"
        >
          <div class="flex">
            <p class="text-center text-sm font-black text-black md:text-xl">
              Kiraket akan merevolusi perdagangan ritel tradisional di pasar Asia, Amerika Selatan, Afrika dan Eropa Timur dengan:
            </p>
          </div>
          <div class="mt-12 flex flex-row">
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                Menyediakan informasi dan wawasan yang mudah diakses bagi pengecer mikro tentang toko dan komunitas mereka sehingga menghasilkan profitabilitas dan arus kas yang lebih besar serta memberikan dampak positif yang nyata terhadap perekonomian negara-negara tersebut
              </p>
            </div>
          </div>
          <div class="mt-4 flex flex-row">
            <div class="flex pr-2">
              <CircleCheck class="text-kira-success h-6" />
            </div>
            <div class="flex items-start">
              <p class="text-xs leading-6 text-gray-400">
                Menyediakan data pasar, penjualan, dan inventaris waktu nyata kepada produsen dan distributor untuk memberikan jawaban paling komprehensif dan waktu nyata atas pertanyaan tersulit mereka
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import globalMixin from '@/mixins/global.js'

// Offers
import CircleCheck from '@/assets/svg/circle-check.svg'
import CircleRight from '@/assets/svg/circle-right.svg'
import CircleLeft from '@/assets/svg/circle-left.svg'
// Flags
import Indonesia from '@/assets/svg/flags/indonesia.svg'
import Philippines from '@/assets/svg/flags/philipphines.svg'
import India from '@/assets/svg/flags/india.svg'

export default {
  name: 'InvestorPage',
  components: {
    CircleCheck,
    CircleLeft,
    CircleRight,
    Indonesia,
    Philippines,
    India,
  },
  mixins: [globalMixin],
  data: () => ({}),
  methods: {},
}
</script>
