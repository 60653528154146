export default {
  language: 'Bahasa',
  appName: 'Kiraket',
  greeting: 'Halo Dunia!',
  hello: 'Halo',
  location: 'Jakarta',
  home: 'Beranda',
  tenants: 'Tenant',
  menus: 'Menu',
  tenantNear: 'Tenant Terdekat',
  category: 'Kategori',
  order: 'Pesanan',
  lastOrdered: 'Terakhir Dipesan',
  orderHistory: 'Riwayat Pesanan',
  recommendationForYou: 'Rekomendasi Untuk Kamu',
  fromTenant: 'Dari tenant ini',
  seeAll: 'Lihat Semua',
  seeHistory: 'Lihat Riwayat',
  seeTenant: 'Lihat Tenant',
  detail: 'Detail',
  seeDetail: 'Lihat Detail',
  payNow: 'Bayar Sekarang',
  changeMethod: 'Ubah Metode',
  payOrder: 'Bayar Pesanan',
  seeHowToPay: 'Lihat Cara Bayar',
  sale: 'Promo',
  cart: 'Keranjang',
  wallet: 'Dompet',
  points: 'Poin',
  filter: 'Filter',
  sort: 'Urutkan',
  price: 'Harga',
  minimal: 'Minimal',
  maximal: 'Maksimal',
  account: 'Akun',
  scanQR: 'Scan QR',
  scanAQRCode: 'Scan a QR Code',
  product: 'Produk',
  productCategory: 'Kategori Produk',
  productRecommendation: 'Rekomendasi Produk',
  notification: 'Notifikasi',
  products: 'produk',
  tenants: 'tenant',
  changeLanguage: 'Ubah Bahasa',
  balance: 'Saldo',
  balanceAfter: 'Saldo setelah transaksi',
  topup: 'Top up',
  pendingTopup: 'Top up Tertunda',
  myPoints: 'Poin Saya',
  inviteFriends: 'Undang Teman',
  termsAndConditions: 'Syarat dan Ketentuan',
  privacyPolicy: 'Kebijakan Privasi',
  logout: 'Keluar',
  email: 'Alamat Email',
  password: 'Password',
  passwordConfirmation: 'Konfirmasi Password',
  forgotPassword: 'Lupa password?',
  forgotPIN: 'Lupa PIN Kamu?',
  resetPIN: 'Reset PIN',
  forgotPasswordDesc:
    'Silahkan isi form di bawah dengan nomor WhatsApp yang kamu gunakan untuk mendaftar. Kamu akan dikirimi nomor WhatsApp berisi petunjuk tentang cara mereset PIN kamu.',
  submit: 'Kirim',
  sendEmail: 'Kirim Email',
  sendOTP: 'Kirim OTP',
  resetPassword: 'Reset Password',
  resetPasswordDesc:
    'Permintaan reset password berhasil, silakan buat password baru untuk akun ',
  newPassword: 'Password Baru',
  newPasswordConfirmation: 'Konfirmasi Password Baru',
  verificationCheckEmail:
    'Silahkan cek email kamu untuk mengetahui kode verifikasi',
  login: 'Masuk',
  save: 'Simpan',
  welcome: 'Selamat Datang',
  welcomeTo: 'Selamat Datang di',
  asCustomer: 'Sebagai Customer',
  asTenant: 'Sebagai Tenant',
  back: 'Kembali',
  wrong_email_or_password: 'Email atau password salah',
  wrong_phone_or_pin: 'Nomor WhatsApp atau PIN salah',
  phone_number_not_valid:
    'Nomor WhatsApp tidak valid, mohon diawali dengan kode negara dengan minimal 10 digit',
  phone_number_required: 'Nomor WhatsApp harus diisi',
  information: 'Informasi',
  you_are_not_logged_in: 'Kamu belum login',
  you_have_successfully_registered: 'Kamu berhasil terdaftar',
  registration_failed: 'Registrasi user gagal',
  you_have_successfully_logged_in: 'Kamu berhasil login',
  error_send_review: 'Review gagal dikirim',
  enter_review: 'Mohon masukkan review kamu',
  enter_email: 'Mohon masukkan email kamu',
  enter_password: 'Mohon masukkan password kamu',
  enter_passwordConfirmation: 'Mohon masukkan konfirmasi password kamu',
  enter_phoneNumber: 'Nomor WhatsApp',
  enter_ewalletNumber: 'Nomor ewallet kamu',
  enter_verificationCode: 'Mohon masukkan kode verifikasi kamu',
  enter_name: 'Mohon masukkan nama kamu',
  valid_email_address: 'Mohon masukkan alamat email yang valid',
  valid_phone_number: 'Mohon masukkan nomor WhatsApp yang valid',
  valid_ewallet_number: 'Mohon masukkan nomor ewallet yang valid',
  valid_qty: 'Mohon masukkan qty yang valid',
  email_sent: 'Email Terkirim',
  otp_sent: 'OTP telah dikirim',
  account_verified: 'Akun Terverifikasi',
  verify_code_true: 'Kode verifikasi benar',
  verify_code_digit: 'Kode verifikasi harus 4 digit',
  pin_code_digit: 'Pin harus 6 digit',
  field_must_be_alphabet: 'hanya diperbolehkan huruf saja',
  field_must_be_alphanumeric: 'hanya diperbolehkan huruf dan angka saja',
  field_must_be_numeric: 'hanya diperbolehkan angka saja',
  password_only_allowed_alphanumeric_and_special_characters:
    'Password hanya diperbolehkan huruf, angka dan spesial karakter',
  confirmation: 'Konfirmasi',
  are_you_sure_want_to_logout: 'Apakah kamu yakin ingin keluar?',
  yes: 'Ya',
  no: 'Tidak',
  find_food_or_drink: 'Cari makanan & minuman',
  data_not_found: 'Data tidak ditemukan',
  tab_pending: 'Tertunda',
  tab_on_process: 'Diproses',
  tab_done: 'Selesai',
  tab_cancelled: 'Dibatalkan',
  loginDesc: 'Masukkan e-mail dan passwordmu',
  noAccount: 'Belum punya akun?',
  joinUs: 'Yuk daftar',
  register: 'Daftar',
  name: 'Nama',
  phoneNumber: 'Nomor WhatsApp',
  insertPhoneNumberEwallet: 'Masukkan nomor WhatsApp ewallet',
  verificationCode: 'Kode Verifikasi',
  registerAggreement: 'Dengan mendaftar, kamu sudah membaca dan setuju dengan',
  termsOfUse: 'Terms of Use',
  and: 'dan',
  next: 'Lanjut',
  haveAccount: 'Sudah punya akun?',
  accountVerification: 'Verifikasi Akun',
  enterNewPin: 'Masukkan PIN baru kamu',
  confirmNewPin: 'Konfirmasi PIN baru kamu',
  enterPin: 'Masukkan PIN kamu',
  resendCode: 'Kirim ulang kode',
  institution: 'Instansi',
  yourInstitution: 'Instansi Kamu',
  idNumber: 'Nomor Induk',
  enter_idNumber: 'Mohon masukkan Nomor Induk yang valid',
  finish: 'Selesai',
  continueShopping: 'Lanjut Belanja',
  cartEmptyNotice: 'Keranjang belanjamu masih kosong',
  cartEmptyNoticeDetail: 'Yuk, isi keranjangmu dengan beli jajanan kesukaanmu',
  addToCart: 'Masukkan keranjang',
  add: 'Add',
  showResult: 'Tampilkan Hasil',
  reset: 'Reset',
  total: 'Total',
  with_fee: '(+fee)',
  point_gained: 'Poin yang diterima',
  subTotal: 'Subtotal',
  discount: 'Diskon',
  unique_code: 'Kode Unik',
  point_reward: 'Poin yang didapatkan',
  promo: 'Promo',
  usePromo: 'Makin hemat dengan promo',
  detailPromo: 'Detail promo',
  usingPromo: 'Pakai Promo',
  youCanSave: 'Kamu bisa hemat',
  enterPromoCode: 'Masukkan kode promo',
  promoEnd: 'Berakhir pada',
  promoValidUntil: 'Valid sampai ',
  promoDay: 'hari lagi',
  showDetail: 'Lihat detail',
  applicableArea: 'Daerah yang berlaku',
  promoUsed: '1 promo dipakai',
  use: 'Terapkan',
  promoCodeEmpty: 'Mohon masukkan kode promo terlebih dahulu',
  pleaseChoosePromo: 'Silakan pilih promo terlebih dahulu',
  checkout: 'Checkout',
  delete: 'Hapus',
  chooseAll: 'Pilih Semua',
  addNote: 'Tambahkan Catatan',
  note: 'Notes',
  edit: 'Ubah',
  foods: 'Makanan',
  drinks: 'Minuman',
  search_tenant: 'Cari Tenant',
  search_menu: 'Cari Menu',
  success: 'Berhasil',
  successfully_added_to_cart: 'Berhasil menambahkan ke keranjang',
  failed_add_to_cart: 'Gagal menambahkan ke keranjang',
  successfully_add_qty: 'Berhasil menambahkan qty',
  successfully_sub_qty: 'Berhasil mengurangi qty',
  successfully_delete_from_cart: 'Berhasil menghapus dari keranjang',
  success_change_note: 'Berhasil mengubah notes',
  success_get_data: 'Berhasil meload data',
  failed: 'Gagal',
  failed_get_data: 'Data gagal diload',
  description: 'Deskripsi',
  detail_product: 'Detail Produk',
  process_time: 'Waktu Proses',
  availability: 'Ketersediaan',
  in_stock: 'Stok tersedia',
  out_stock: 'Stok habis',
  product_not_found: 'Detail produk tidak ditemukan',
  promo_not_found: 'Detail promo tidak ditemukan',
  serving_for: 'Pesan Untuk',
  serving_for_later: 'Pesan untuk nanti',
  oclock: 'Pukul',
  select_date: 'Pilih tanggal',
  select_hour: 'Pilih jam',
  select_minute: 'Pilih menit',
  now: 'Sekarang',
  process_payment: 'Ke Pembayaran',
  process_payment_confirmation: 'Konfirmasi Pembayaran',
  pay: 'Bayar',
  with_cash: 'dengan cash',
  no_data: 'Tidak ada data',
  no_more_data: 'Tidak ada lagi data yang dapat ditampilkan',
  no_result: 'Tidak ada hasil yang dapat ditampilkan',
  no_photo_available: 'Tidak ada foto yang tersedia',
  order_success: 'Pesananmu berhasil!',
  order_success_desc: 'Terima kasih, pesananmu saat ini\nsedang diproses',
  order_failed: 'Pesananmu gagal!',
  order_failed_desc:
    'Maaf, tampaknya saldomu tidak\nmencukupi untuk pesanan ini',
  order_pending: 'Order Pending',
  status_pending: 'Pending',
  order_status: 'Order kamu berstatus ',
  order_pending_desc: 'Silakan bayar sesuai metode pembayaran yang kamu pilih',
  order_pending_cash_desc:
    'Silakan menuju kasir untuk menyelesaikan pembayaran kamu',
  order_pending_subdesc: 'Nominal pembayaran harus sesuai hingga ',
  order_pending_cash_subdesc: 'Harap siapkan uang pas',
  last_3_digit: 'tiga digit terakhir',
  already_paid: 'Saya sudah bayar',
  back_to_home: 'Kembali ke Beranda',
  estimated_time: 'Estimasi waktu',
  minute: 'Menit',
  openModal: 'Buka Modal',
  track_your_order: 'Lacak Pesananmu',
  track_my_order: 'Lacak Pesananku',
  have_you_order_yet: 'Sudah pesan belum?',
  have_not_order_pending_description:
    'Tidak ada pesanan yang tertunda di sini. Kamu dapat membuat pesanan baru dengan mengklik tombol di bawah ini.',
  order_no_item: 'Tidak ada pesanan',
  order_no_pending: 'Tidak ada pesananmu yang\npending di sini',
  order_no_process: 'Tidak ada pesananmu yang\nsedang diproses di sini',
  order_no_done: 'Tidak ada pesananmu yang\nsudah selesai di sini',
  order_no_cancelled: 'Tidak ada pesananmu yang\ndibatalkan di sini',
  your_order: 'Pesanan Kamu',
  id_order: 'ID order',
  order_tab_pending: 'Pending',
  order_tab_onprocess: 'Diproses',
  order_tab_done: 'Selesai',
  order_tab_cancelled: 'Dibatalkan',
  tracking_cancelled: 'Dibatalkan',
  other_item: 'item lainnya',
  order_waiting_confirmation: 'Menunggu Konfirmasi',
  order_ready: 'Pesanan Siap',
  order_complete_payment: 'Selesaikan Pembayaran',
  order_reorder_btn: 'Beli Lagi',
  order_ready_btn: 'Sudah Siap',
  order_onprocess_btn: 'Sedang Diproses',
  order_onprocess_desc: 'Estimasi',
  time_hour: 'Jam',
  time_minute: 'Menit',
  order_cancel_btn: 'Batalkan',
  order_cancel_title: 'Batalkan pesanan',
  order_cancel_all: 'Batalkan semua pesanan',
  order_cancel_no_item_selected:
    'Silakan pilih setidaknya satu item untuk membatalkan pesanan',
  order_cancel_desc: 'Kamu yakin mau batalkan pesanan ini?',
  order_cancel_success: 'Pesanan berhasil dibatalkan',
  order_cancel_failed: 'Pesanan gagal dibatalkan',
  order_review_success: 'Pesanan berhasil diulas',
  order_review_failed: 'Pesanan gagal diulas',
  order_confirmed: 'Pesanan Dikonfirmasi',
  review_appbar: 'Beri ulasan',
  review_only_shown_by: 'Ulasan hanya bisa dilihat oleh pihak tenant',
  give_rating: 'Beri penilaian',
  et_rating: 'Mohon beri penilaian terlebih dahulu',
  write_review: 'Beri ulasan',
  write_review_here: 'Beri ulasan di sini',
  add_photo: 'Upload foto barang',
  gallery: 'Galeri',
  camera: 'Kamera',
  track_appbar: 'Lacak Pesananmu',
  tracking_confirmed: 'Pesanan dikonfirmasi',
  tracking_on_progress: 'Pesanan diproses',
  tracking_almost_done: 'Hampir selesai',
  tracking_ready: 'Pesanan siap diambil',
  tracking_done: 'Selesai',
  tracking_completed: 'Selesai',
  tracking_canceled: 'Pesanan dibatalkan',
  tracking_status_confirmed: 'Dikonfirmasi',
  tracking_status_on_progress: 'Diproses',
  tracking_status_completed: 'Selesai',
  tracking_status_cancelled: 'Dibatalkan',
  tracking_waiting_confirmation_title: 'Menunggu konfirmasi penjual',
  tracking_waiting_confirmation_desc: 'Akan dikonfirmasi sebelum pukul',
  tracking_complete_your_payment: 'Selesaikan pembayaranmu',
  tracking_complete_your_payment_desc:
    'Pembayaranmu akan dikonfirmasi sebelum pukul',
  tracking_on_progress_title: 'Estimasi pesanan selesai',
  tracking_on_progress_desc: '@time1 - @time2',
  tracking_ready_title: 'Pesananmu sudah siap!',
  tracking_ready_desc: 'Kamu bisa mengambil pesananmu sekarang',
  tracking_done_title: 'Pesanan selesai',
  tracking_done_desc: 'Terima kasih, jajan lagi ya!',
  tracking_review: 'Beri ulasan',
  tracking_reviewed: 'Sudah diberi ulasan',
  tracking_order_details: 'Detail Pesanan',
  pickup_or_delivered: 'Ambil sendiri atau diantar',
  pickup: 'Ambil sendiri',
  delivered: 'Diantar',
  to_pickup: 'Diambil',
  update: 'Perbarui',
  update_order_status: 'Perbarui status pesanan',
  order_status_updated: 'Status pesanan berhasil diperbarui',
  select_all: 'Pilih semua',
  all_status: 'Semua status',
  promo_available: 'Promo tersedia',
  successfully_cancelled: 'Berhasil dibatalkan!',
  added_to_cart: 'Pesanan ditambahkan ke keranjang!',
  table_row_changed: 'Nomor meja berhasil diubah',
  table_row_failed: 'Nomor meja gagal diubah',
  failed_fetch_data: 'Gagal ambil data',
  cant_use_promo_code: 'Kode promo tidak berlaku',
  review_thanks: 'Terima kasih reviewnya, jajan lagi yaa...',
  review_failed: 'Gagal memberikan review',
  waiting_update_status: 'Menunggu update status',
  total_order: 'Total pesanan',
  total_payment: 'Total bayar',
  round_price: 'Pembulatan',
  waiting_for_payment: 'Pembayaran akan dibatalkan dalam',
  newest: 'Terbaru',
  payment: 'Pembayaran',
  qris: 'QRIS',
  platform_fee: 'Platform fee',
  tax_fee: 'Tax Fee',
  service_fee: 'Service Fee',
  reason_cancellation: 'Alasan pembatalan',
  table_row: 'Nomor meja',
  edit_table_row: 'Ubah nomor meja',
  insert_table_row: 'Masukkan nomor meja',
  want_to_change_table_row: 'Ingin mengubah nomor meja?',
  where_you_sitting: 'Di mana kamu duduk?',
  input_table_row:
    'Input nomor meja di mana kamu duduk, agar tenant lebih mudah mengantarkan pesanan kamu',
  set_table: 'Set nomor meja',
  payment_method: 'Metode pembayaran',
  select_payment_method: 'Pilih Metode Pembayaran',
  select: 'Pilih',
  qris_scan: 'Scan QRIS',
  ewallet: 'E-wallet',
  va: 'Transfer Virtual Account',
  verification_code_desc:
    'Masukkan kode OTP yang dikirim melalui WhatsApp ke nomor',
  register_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk mendaftar',
  login_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk login',
  login_or_register_desc:
    'Halo, sebelum melanjutkan, mohon input nomor WhatsApp kamu di bawah ini untuk login atau mendaftar',
  error_resend_otp: 'Silakan tunggu sebelum bisa mengirim kembali OTP',
  transaction_pending: 'Mohon selesaikan pembayaran terlebih dahulu',
  transaction_success: 'Transaksi berhasil',
  enter_new_pin: 'Masukan PIN baru kamu',
  enter_pin: 'Masukan PIN kamu',
  confirm_new_pin: 'Konfirmasi PIN baru kamu',
  confirm_pin_not_match: 'Konfirmasi PIN tidak sama',
  enter_table_row: 'Nomor meja wajib diisi',
  payment_method_required: 'Pilih metode pembayaran terlebih dahulu',
  edit_account: 'Edit Akun',
  edit_phone: 'Edit Nomor WhatsApp',
  transfer_to: 'Transfer ke',
  update_profile: 'Update Akun',
  dine_in: 'Dine In',
  take_away: 'Take Away',
  please_rescan_qr: 'InstituteId kadaluwarsa, silakan scan ulang QR Code',
  tenant_closed: 'Restoran tutup sementara',
  sorry: 'Mohon maaf...',
  under_maintenance_sorry: 'Under Maintenance',
  service_closed: 'Untuk saat ini tidak melayani pemesanan melalui kiraket 🙏🏻',
  service_maintenance: 'Mohon maaf, kami sedang dalam pembaruan sistem :(',
  please_wait: 'Ditunggu sebentar, ya!',
  under_maintenance: 'Website saat ini sedang dalam proses perbaikan',
  under_maintenance_thankyou:
    'Terima kasih atas pengertiannya, sampai jumpa kembali',
  order_type: 'Tipe Order',
  please_select_valid_date:
    'Mohon pilih tanggal yang valid sampai 30 hari ke depan',
  please_select_valid_hour: 'Mohon pilih jam yang valid',
  please_select_valid_minute: 'Mohon pilih menit yang valid',
  additional_menu: 'Tambahkan Menu',
  addon_menu: 'Menu tambahan:',
  add_another_items: 'Tambahkan items lainnya',
  required: 'Required',
  cash: 'Cash',
  cash_payment_info:
    '*Pembayaran menggunakan cash hanya tersedia untuk pemesanan secara langsung dan dalam satu tenant saja',
  copy: 'Salin',
  copied: 'Disalin',
  copy_to_clipboard: 'Salin ke papan klip',
  copy_to_clipboard_success: 'disalin ke papan klip',
  copy_to_clipboard_failed: 'Gagal menyalin ke papan klip',
  please_select_institute: 'Pilih restoran atau institusi terlebih dahulu',
  landing_desc:
    'Halo, sebelum melanjutkan, mohon pilih restoran atau institusi di bawah ini',
  coming_soon: 'Coming soon.. 🙏🏻',
  pageNotFound: 'Halaman tidak ditemukan',
  pageNotFoundDesc: 'Oops, sepertinya halaman yang kamu cari tidak ada di sini',
  pageNotFoundSubDesc: '(error 404)',
  pleaseChooseItem: 'Silakan pilih menu sebelum checkout',
  maxCartNotes: 'Notes tidak boleh melebihi 255 karakter',
  item: 'item',

  privacySentence1:
    'Kebijakan privasi ini (“Kebijakan Privasi”) akan menjelaskan bagaimana PT Kanovasi InMaya Nusantara dan PT Kiraket Technology Solutions dan setiap anak perusahaan serta perusahaan afiliasinya (“Kami”), memperoleh, mengumpulkan, menggunakan, menampilkan, mengumumkan, mengungkapkan, memproses, membukakan akses, menyimpan, mengirim, memberi, mengalihkan, mengolah, mengelola, memusnahkan dan melindungi informasi dan data pribadi (secara bersama-sama, “Pemanfaatan”) yang anda sebagai pengguna (“Anda”) Platform (sebagaimana didefinisikan di bawah) berikan sebagaimana diminta maupun pada saat menggunakan Platform (“Data Pribadi”). Perlu dicatat bahwa Data Pribadi di sini tidak termasuk Data Pribadi yang telah tersedia di domain publik.',
  privacySentence2:
    'Kebijakan Privasi ini merupakan bagian dari Syarat dan Ketentuan Penggunaan (“Ketentuan Penggunaan”) Kami. Penggunaan Platform dan setiap fitur dan/atau layanan yang tersedia dalam Platform (“Layanan”) merupakan bentuk persetujuan anda terhadap Ketentuan Penggunaan dan Kebijakan Privasi tersebut. Oleh karena itu, Anda perlu untuk membaca Kebijakan Privasi ini dengan saksama untuk memastikan bahwa Anda memahaminya sepenuhnya sebelum mendaftar, mengakses dan/atau menggunakan Platform dan Layanan Kami.',
  privacyScopeTitle: 'Lingkup Kebijakan Privasi',
  privacyScope1: 'Kebijakan Privasi ini mengatur Pemanfaatan Data Pribadi',
  privacyScope2:
    'Dengan menggunakan Platform, maka Anda dianggap telah membaca Kebijakan Privasi ini dan menyetujui mekanisme Pemanfaatan Data Pribadi Anda sebagaimana diatur dalam Kebijakan Privasi ini.',
  privacyScope3:
    'Apabila Kami meminta Anda untuk memberikan informasi ketika menggunakan Platform, maka informasi tersebut itu hanya akan digunakan untuk keperluan pemberian Layanan sesuai dengan Kebijakan Privasi ini.',
  privacyScope4:
    'Kami dapat mengubah, menghapus dan untuk menerapkan ketentuan baru dalam Kebijakan Privasi ini. Anda diharapkan untuk memeriksa halaman Kebijakan Privasi ini secara berkala untuk mengetahui perubahan tersebut. Dengan menggunakan Platform setelah terjadinya perubahan tersebut, Anda dianggap telah mengetahui dan menyetujui perubahan-perubahan ketentuan pada Kebijakan Privasi ini.',
  privacyRegistrationTitle: 'Registrasi',
  privacyRegistration1:
    'Anda diharuskan melakukan pendaftaran dalam Platform untuk dapat menggunakan fitur - fitur pada Platform.',
  privacyRegistration2:
    'Untuk melakukan pendaftaran dalam Platform, Anda harus memberikan informasi yang Kami perlukan sebagaimana tercantum pada Poin C (Data Pribadi) di bawah ini.',
  privacyPersonalDataTitle: 'Data Pribadi',
  privacyPersonalData1:
    'Anda mengetahui dan menyetujui bahwa Kami mengumpulkan informasi pribadi yang diberikan Anda saat Anda membuat akun dan profil maupun pada saat menggunakan fitur-fitur yang terdapat dalam Platform.',
  privacyPersonalData2:
    'Informasi mengenai identitas diri yang wajib diisi oleh Anda saat membuat akun di Platform antara lain adalah:',
  privacyPersonalData2Sub1:
    'Nama lengkap sesuai kartu identitas yang berlaku (KTP atau Paspor); dan',
  privacyPersonalData2Sub2:
    'Nomor telepon genggam, baik yang terhubung dengan WhatsApp ataupun tidak.',
  privacyPersonalData3:
    'Anda dapat mengaktifkan atau menonaktifkan layanan pengenalan lokasi saat Anda menggunakan Platform. ',
  privacyPersonalData4:
    'Apabila diperlukan, Kami dapat melakukan verifikasi langsung kepada Anda tentang data diri yang telah Anda sampaikan melalui Platform.',
  privacyPersonalData5: 'Informasi yang Anda berikan adalah akurat dan benar.',
  privacyVisitorDataTitle: 'Data Pengunjung',
  privacyVisitorDataSentence:
    'Anda mengetahui dan menyetujui bahwa Platform akan mengumpulkan informasi tentang penggunaan, seperti (namun tidak terbatas pada) jumlah kunjungan, sumber pengunjung dan aktivitas pengunjung. Data ini kami kumpulkan dan kami gunakan dengan tujuan meningkatkan kepuasan Anda serta layanan Kami.',
  privacyVisitorData1:
    'Kami dan Penyedia Layanan dapat melakukan Pemanfaatan Data Pribadi Anda untuk kegiatan bisnis dan operasional sesuai dengan tujuan diberikannya Data Pribadi tersebut, termasuk untuk:',
  privacyVisitorData1Sub1:
    'Menyediakan informasi kepada Anda dan Penyedia Layanan sesuai permintaan Anda berkaitan dengan Layanan Kami.',
  privacyVisitorData1Sub2:
    'Mengembangkan, meningkatkan dan menyediakan produk dan layanan yang sesuai dengan kebutuhan Anda.',
  privacyVisitorData1Sub3: 'Menanggapi pertanyaan, komentar dan masukan Anda.',
  privacyVisitorData1Sub4:
    'Memberikan informasi/mengirimkan e-mail dan/atau SMS berisi promosi secara berkala kepada Anda bila terdapat suatu penawaran dan/atau promosi atas produk-produk baru, penawaran khusus, atau informasi lain yang menurut Kami diperlukan oleh Anda.',
  privacyVisitorData1Sub5:
    'Menggunakan dan mengungkapkan informasi ini jika diperlukan bagi tinjauan medis, jasa hukum, dan audit, meliputi penipuan dan deteksi penyalahgunaan dan program penyesuaian, serta perencanaan dan manajemen bisnis.',
  privacyVisitorData2:
    'Kami menjamin bahwa data dan informasi yang diberikan Anda bersifat rahasia dan tidak akan disebarluaskan kecuali untuk hal-hal yang telah dicantumkan dan Anda setujui dalam Kebijakan Privasi ini. Untuk mencegah akses tidak sah, Kami melakukan tindakan pengamanan fisik, elektronik, dan prosedur manajerial untuk melindungi informasi Anda.',
  privacyVisitorData3:
    'Informasi kesehatan Anda hanya akan digunakan sehubungan dengan pelayanan Platform dan hal-hal yang telah dicantumkan dan Anda setujui dalam Kebijakan Privasi ini dan Kami hanya akan mengumpulkan informasi yang dibutuhkan untuk menjalankan pelayanan dalam Platform.',
  privacyOtherPlatformsTitle: 'Koneksi Anda Ke Platform Lain',
  privacyOtherPlatformsSentence:
    'Platform dapat memuat tautan menuju platform milik pihak ketiga (“Platform Pihak Ketiga”) dan konten pihak ketiga (“Konten Pihak Ketiga”). Untuk keamanan Anda, Anda perlu mempelajari dan membaca dengan hati-hati kebijakan penanganan informasi pribadi yang berlaku di Platform Pihak Ketiga dan/atau Konten Pihak Ketiga.',
  privacyLawTitle: 'Hukum Yang Berlaku',
  privacyLawSentence:
    'Kebijakan Privasi ini diatur berdasarkan hukum Negara Republik Indonesia dan Anda diwajibkan tunduk kepada semua peraturan yang berlaku di Republik Indonesia.',
  privacySecurityTitle: 'Upaya Pengamanan',
  privacySecurity1:
    'Kami akan berupaya memastikan bahwa informasi yang Anda berikan kepada Kami aman dan tidak dapat digunakan oleh pihak-pihak yang tidak bertanggung jawab. Untuk keamanan data Anda, Kami sangat menyarankan agar Anda selalu memperbarui Platform dan perangkat lunak anda serta tidak mengungkapkan kata sandi anda kepada pihak manapun.',
  privacySecurity2:
    'Anda dengan ini setuju bahwa Kami dapat menyimpan Data Pribadi pada server yang terletak di pusat data yang ditunjuk oleh Kami. Pemanfaatan Data Pribadi sehubungan dengan penggunaan Platform akan terus diatur oleh Kebijakan Privasi ini sesuai dengan peraturan perundangan-undangan yang berlaku di Republik Indonesia.',
  privacyContactTitle: 'Pertanyaan, keluhan dan kontak',
  privacyContactSentence:
    'Jika Anda memiliki pertanyaan tentang kebijakan kerahasiaan ini, peraturan-peraturan Kami, hak Anda, dan hubungan Anda dengan Platform, silakan mengirim surat elektronik Kami di:',
}

// export default id
